import 'twin.macro';
import Badge from 'components/badge/Badge';
import Button from 'components/button/Button';
import React from 'react';
import { User } from 'types/userTypes';
import PermissionGrid from 'components/permissions/permission-grid/PermissionGrid';
import useNavigationContext from 'hooks/context/nav-context';
import { PermissionLevel } from '../../../types/permissionTypes';
import usePermissionContext from 'hooks/context/permission-context';
import { RoleData } from './UsersConfig';
import { PERMISSIONS } from 'context/PermissionContext';

const ColleaguesListItem = ({
  colleague,
  removable,
  onResend,
  onUnlink,
  loading,
  onUpdate,
}: {
  colleague: User;
  removable: boolean;
  onResend: () => void;
  onUnlink: () => void;
  loading: boolean;
  onUpdate: (userId: number, roles: RoleData[]) => void;
}) => {
  const { hasRolesEnabled } = useNavigationContext();
  const { allRoles, userHasPermission } = usePermissionContext();

  const flattenRoles = (
    nodes: typeof colleague.roles,
    parentRoles: string[] = []
  ): RoleData[] => {
    return nodes.flatMap((node) => {
      // Filter current roles by checking if they are not already in the parent roles
      const currentRoles = node.roles
        .filter((r) => !parentRoles.includes(r)) // Only include roles that are not in parentRoles
        .map((r) => ({
          role_id: allRoles?.find((role) => role.name === r)?.id ?? 0,
          role_name: r,
          model_id: node.id,
          model_type: node.model_type,
        }));

      // For children, pass only the immediate parent's roles to check for new roles
      const childrenRoles = node.children
        ? flattenRoles(node.children, node.roles) // Only pass down the parent's roles (not the grandparent's)
        : [];

      return [...currentRoles, ...childrenRoles];
    });
  };

  return (
    <div tw="mt-1 flex w-full flex-row flex-wrap justify-between rounded border-2 border-solid border-gray-200 p-4 relative ">
      <div tw="flex flex-col max-w-full">
        <span tw="float-left flex flex-row font-bold">
          {`${colleague?.firstname} ${colleague?.lastname}`}
        </span>
        <span tw="text-sm text-gray-500">{colleague?.email}</span>

        {hasRolesEnabled && (
          <React.Fragment>
            <PermissionGrid
              roles={allRoles ?? []}
              userId={colleague.id}
              nodes={colleague.roles}
              onRoleChange={(nodes: PermissionLevel[]) => {
                const flattenedRoles = flattenRoles(nodes);
                onUpdate(colleague.id, flattenedRoles);
              }}
            />
          </React.Fragment>
        )}
      </div>

      <div tw="my-auto">
        <div tw="flex items-center space-x-4 py-4">
          {!colleague?.verified && (
            <>
              <Badge label={'Pending'} color="gray" />
              <Button variant="link" onClick={onResend} loading={loading}>
                {loading ? 'Resending invite' : 'Resend invite'}
              </Button>
            </>
          )}
          {removable &&
            userHasPermission(PERMISSIONS.MANAGE_USERS, 'customer') && (
              <Button variant="link" onClick={onUnlink}>
                Unlink
              </Button>
            )}
        </div>
      </div>
    </div>
  );
};

export default ColleaguesListItem;
