import tinycolor from 'tinycolor2';
import { AdVariant } from 'types/adEditorTypes';
import { AdPlacement } from 'types/adTypes';
import { Brand } from 'types/customer/types';

const insertNewLines = (text: string, maxLength: number = 25) => {
  const words = text.split(' ');
  let currentLine = '';
  let result = '';

  for (const word of words) {
    if (currentLine.length + word.length + 1 > maxLength) {
      if (result.length > 0) {
        result += '{NEW_LINE_BREAK}';
      }
      result += currentLine.trim();
      currentLine = word + ' ';
    } else {
      currentLine += word + ' ';
    }
  }

  if (currentLine.trim().length > 0) {
    if (result.length > 0) {
      result += '{NEW_LINE_BREAK}';
    }
    result += currentLine.trim();
  }

  return result;
};

/**
const injectText = (haystack: string, needle: string, number: number) =>
  haystack.replaceAll(needle, number.toString());
*/

interface OverlayConfig {
  width: number;
  height: number;
  logoHeight: number;
}

const storyConfig: OverlayConfig = {
  width: 1080,
  height: 1920,
  logoHeight: 150,
};

const feedConfig: OverlayConfig = {
  width: 1000,
  height: 1000,
  logoHeight: 120,
};

/**
 * Get an overlay function
 * @param overlay => Overlay string
 * @param currentVariant => Current active ad variant
 * @param colors? => Default colors for texts
 */
export const getOverlay = (
  overlay: string,
  currentVariant?: AdVariant,
  brand?: Brand
) => {
  let rText = overlay;

  const primaryColor = brand?.default_primary_color ?? '#fd535a';
  const secondaryColor = brand?.default_secondary_color ?? 'rgb(200,200,200)';

  const isPrimaryBright = tinycolor(
    brand?.default_primary_color ?? 'rgb(200,200,200)'
  ).isLight();
  const isSecondaryBright = tinycolor(
    brand?.default_secondary_color ?? 'rgb(200,200,200)'
  ).isLight();

  const brightColor = isPrimaryBright
    ? primaryColor
    : isSecondaryBright
    ? secondaryColor
    : 'rgb(255,255,255)';
  const darkColor = !isPrimaryBright
    ? primaryColor
    : !isSecondaryBright
    ? secondaryColor
    : 'rgb(0,0,0)';

  const jobFunction =
    currentVariant?.function.replaceAll(' & ', ' &amp; ') ?? '[JOB]';
  const customer =
    currentVariant?.company.replaceAll(' & ', ' &amp; ') ?? '[COMPANY]';

  const isStories = currentVariant?.placement === AdPlacement.STORIES;

  const margin = 25;
  const width = isStories ? storyConfig.width : feedConfig.width;
  const height = isStories ? storyConfig.height : feedConfig.height;
  const logo = currentVariant?.logo;
  const logoRatio = logo ? logo.width! / logo.height! : 1;
  const logoHeight = isStories ? storyConfig.logoHeight : feedConfig.logoHeight;
  const logoWidth = logoRatio * logoHeight;

  // Assume original image dimensions come from currentVariant.path
  const originalImageWidth = currentVariant?.path?.width ?? 1920;
  const originalImageHeight = currentVariant?.path?.height ?? 1080;
  const containerAspect = width / height;
  const imageAspect = originalImageWidth / originalImageHeight;
  let imageX = 0;
  let imageY = 0;
  let displayWidth = isStories ? storyConfig.width : feedConfig.width;
  let displayHeight = isStories ? storyConfig.height : feedConfig.height;

  if (imageAspect > containerAspect) {
    // we need the viewbox width to calculate the offset
    const tempWidth = displayWidth;
    // Image is relatively wider than the container
    // Match container height, center horizontally
    const scale = displayHeight / originalImageHeight;
    displayWidth = originalImageWidth * scale;
    imageX = (tempWidth - displayWidth) / 2;
  } else {
    // we need the viewbox height to calculae the offset
    const tempHeight = displayHeight;
    // Image is relatively taller or equal
    // Match container width, center vertically
    const scale = displayWidth / originalImageWidth;
    displayHeight = originalImageHeight * scale;
    imageY = (tempHeight - displayHeight) / 2;
  }

  const removeLogo = (svg: string) => {
    const parser = new DOMParser();
    const serializer = new XMLSerializer();

    const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
    const images = svgDoc.getElementsByTagName('image');

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      if (image.getAttribute('width') === '{LOGO_WIDTH}')
        image.parentNode?.removeChild(image);
    }

    return serializer.serializeToString(svgDoc);
  };

  const variables = {
    '{IMAGE_URL}':
      currentVariant?.path?.path ??
      'https://t3.ftcdn.net/jpg/02/68/55/60/360_F_268556012_c1WBaKFN5rjRxR2eyV33znK4qnYeKZjm.jpg',
    '{LOGO_URL}': currentVariant?.logo?.path ?? '',
    '{WIDTH}': width.toString(),
    '{HEIGHT}': height.toString(),
    '{IMAGE_X}': imageX.toString(),
    '{IMAGE_Y}': imageY.toString(),
    '{DISPLAY_WIDTH}': displayWidth.toString(),
    '{DISPLAY_HEIGHT}': displayHeight.toString(),
    '{LOGO_RATIO}': logoRatio.toString(),
    '{LOGO_WIDTH}': logoWidth.toString(),
    '{LOGO_X}': (width - margin - logoWidth).toString(),
    '{LOGO_X_CENTER}': (width / 2 - logoWidth / 2).toString(),
    '{BANNER_TITLE}': brand?.default_banner_title ?? 'WANTED',
    '{VAC_NAME_LINE}': insertNewLines(jobFunction),
    '{CUSTOMER}': customer,
    '{VAC_NAME}': jobFunction,
    '{PRIMARY_COLOR}': primaryColor,
    '{SECONDARY_COLOR}': secondaryColor,
    '{BRIGHT_COLOR}': brightColor,
    '{DARK_COLOR}': darkColor,
    '{FUNCTION_FONT_SIZE}': jobFunction?.length > 25 ? '60px' : '80px',
  };

  if (!logo) rText = removeLogo(rText);
  Object.entries(variables).forEach(([key, value]) => {
    rText = rText.replaceAll(key, value.toString());
  });

  return rText;
};

/*
 *
 * Remove the tspan tag
 * <tspan> tag is not supported in FabricJS
 * Source: https://github.com/fabricjs/fabric.js/issues/8439#issuecomment-1315438121
 *
 */
export function deleteTspan(svg: string) {
  const tspanTagMatch = svg.match(/<tspan.*>(.*)<\/tspan>/);
  const tspanAll = svg.match(/<tspan[^>]*>(.*?)<\/tspan>/g);
  let tspanString = null;
  let text: string | null = null;
  let x = null;
  let y = null;
  let groupString = null;
  let matrixString = null;
  let xMatrix = null;
  let yMatrix = null;
  let transformedSvg = svg;
  if (tspanTagMatch && tspanTagMatch.length > 1) {
    text = tspanTagMatch[1] as string;

    tspanString = tspanTagMatch[0];
    const coordMatch = tspanString.match(/x="(-?\d*\.?\d+)" y="(-?\d*\.?\d+)"/);
    if (coordMatch && coordMatch.length > 2) {
      x = parseFloat(coordMatch[1]);
      y = parseFloat(coordMatch[2]);
    }

    const groupTagMatch = svg.match(/<g .*transform.*>/);
    if (groupTagMatch) {
      groupString = groupTagMatch[0];

      // groupString is like <g transform="matrix(a b c d e f)" style=""  > where e = xMatrix et f = yMatrix
      const matrixMatch = groupString.match(
        /matrix\((-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+)\)/
      );
      if (matrixMatch && matrixMatch.length > 6) {
        matrixString = matrixMatch[0];
        xMatrix = parseFloat(matrixMatch[5]);
        yMatrix = parseFloat(matrixMatch[6]);

        if (
          x !== null &&
          y !== null &&
          xMatrix !== null &&
          yMatrix !== null &&
          text !== null &&
          matrixString !== null
        ) {
          const newMatrixString = `matrix(${matrixMatch[1]} ${matrixMatch[2]} ${
            matrixMatch[3]
          } ${matrixMatch[4]} ${x + xMatrix} ${y + yMatrix})`;

          let allText = '';
          tspanAll?.forEach((v, index) => {
            if (index === tspanAll?.length - 1 || tspanAll?.length === 1)
              return;
            const fValue = v.match(/<tspan.*>(.*)<\/tspan>/);
            if (fValue)
              allText +=
                index === 0 ? fValue[1] : `{NEW_LINE_BREAK}${fValue[1]}`;
          });
          allText +=
            tspanAll && tspanAll?.length > 1 ? `{NEW_LINE_BREAK}${text}` : text;
          transformedSvg = svg
            .replace(matrixString, newMatrixString)
            .replace(tspanString, allText);
        }
      }
    }
  }
  return transformedSvg;
}
