import { IntakeFormResults } from 'types/formTypes';
import { IntakeNumberedSectionsSteps } from './intake';
import { IntakeQuestion } from './question';
import {
  IntakeSectionNumberedSteps,
  IntakeStepNumberedQuestions,
} from './steps';
import { StepperStep } from 'types/intakeTypes';

export type StepperDataType = {
  completed: boolean;
  name: string;
  id: number;
  steps: StepperStep[];
};

export interface IntakeState {
  errors: Error[];

  // step checks
  isCompleted: boolean;

  // current ids
  currentIntakeId: number;
  currentSectionId: number;
  currentStepId: number;

  // currentObjects
  currentActiveSection: IntakeSectionNumberedSteps | null | undefined;
  currentActiveStep: IntakeStepNumberedQuestions | null | undefined;
  currentActiveIntake: IntakeNumberedSectionsSteps | null | undefined;

  // current state
  hasStarted: boolean;
  hasFinished: boolean;
  currentIntakeHash: string | null;

  // loading states
  loading: boolean;

  // files
  filesBeingUploaded: string[];

  currentIntakeProgress: number;
  currentIntakeStepperData: StepperDataType[];
  notifications: IntakeNotification[];
  skippedSteps: number[];
}

export interface IntakeHandlers {
  handleSetIntakeHash: (hash: string) => void;
  handleSetIntakeId: (id: number) => void;
  handleSetSectionId: (id: number) => void;
  handleSetStepId: (id: number) => void;
  handleFilesBeingUploaded: (files: string[]) => void;
  handleAddNotification: (notification: IntakeNotification) => void;
  handleRemoveNotification: (notification: IntakeNotification) => void;
  handleSetSkippedSteps: (stepId: number) => void;
  handleStartIntake: () => void;
  handleStepCompleted: (stepId: number) => void;
  handleSetIntakeConfig: (intake: any) => void;
}

export interface APIHandlers {
  fetchIntake: (intakeHash: string) => Promise<Error | TIntakeConfig>;
  lockIntake: (intakeId: number) => Promise<number | Error>;
  submitIntakeStep: (
    stepData: IntakeFormResults
  ) => Promise<
    Error | Pick<IntakeFormResults, 'questions' | 'completed' | 'step'>
  >;
}
export type IntakeCoreContextType = {
  intakeConfig: TIntakeConfig | null;
  brandColors?: [string, string];
  setBrandColors?: (colors: [string, string]) => void;
} & IntakeState &
  IntakeHandlers &
  APIHandlers;

export enum NotificationTypes {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export type IntakeNotification = {
  type: NotificationTypes;
  message: string;
};

export type TIntakeConfig = {
  intakes: {
    [key: string]: IntakeNumberedSectionsSteps;
  };
  questions: {
    [key: string]: IntakeQuestion;
  };
  sections: {
    [key: string]: IntakeSectionNumberedSteps;
  };
  steps: {
    [key: string]: IntakeStepNumberedQuestions;
  };
};
