import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import tw, { styled } from 'twin.macro';

import { Coworker } from 'types/formTypes';
import Button from 'components/button/Button';
import Input from 'components/form/input/Input';
import IntakeInitInput from 'components/form/input/IntakeInitInput';
import { Modal } from 'components/page/app-page/Modal';
import { MAIL_REGEX } from 'utils/regexHelpers';
import Checkbox from 'components/form/checkbox/Checkbox';
import usePermissionContext from 'hooks/context/permission-context';
import { PERMISSIONS } from 'context/PermissionContext';
import PermissionGrid from 'components/permissions/permission-grid/PermissionGrid';
import { PermissionLevel } from 'types/permissionTypes';
import { useQuery } from 'hooks/sympl-query';
import useNavigationContext from 'hooks/context/nav-context';
import { GET_DEFAULT_ROLE_TREE } from 'graphql/users/queries';
import { RoleData } from 'views/settings/users/UsersConfig';

interface IModalCoWorker {
  isShown: boolean;
  onModalClose: () => void;
  currentCoWorker?: Coworker;
  isEditMode: boolean;
  onSave: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    roles: RoleData[]
  ) => void;
  onSaveLoading?: boolean;
  includeLeadRecruiter?: boolean;
}
const ModalCoWorker: React.FC<IModalCoWorker> = ({
  isShown,
  onModalClose,
  currentCoWorker,
  isEditMode,
  onSave,
  onSaveLoading,
  includeLeadRecruiter = true,
}: IModalCoWorker) => {
  const { errors, control } = useFormContext();
  const { userHasPermission, allRoles } = usePermissionContext();
  const { activeCustomer, hasRolesEnabled } = useNavigationContext();
  const stringValidator = (value: string) => !!value.trim();
  const emailValidator = (value: string) =>
    stringValidator(value) && !!value.match(MAIL_REGEX);
  const [treeState, setTreeState] = React.useState<PermissionLevel[]>([]);
  const [userRoles, setUserRoles] = React.useState<RoleData[]>([]);

  useQuery<{
    defaultRoleTree: PermissionLevel;
  }>(GET_DEFAULT_ROLE_TREE, {
    fetchPolicy: 'no-cache',
    skip: !activeCustomer,
    onCompleted: (data) => {
      setTreeState([data.defaultRoleTree]);
    },
  });

  const flattenRoles = (
    nodes: PermissionLevel[],
    parentRoles: string[] = []
  ): RoleData[] => {
    return nodes.flatMap((node) => {
      const currentRoles = node.roles
        .filter((r) => !parentRoles.includes(r))
        .map((r) => ({
          role_id: allRoles?.find((role) => role.name === r)?.id ?? 0,
          role_name: r,
          model_id: node.id,
          model_type: node.model_type,
        }));

      const childrenRoles = node.children
        ? flattenRoles(node.children, node.roles)
        : [];
      return [...currentRoles, ...childrenRoles];
    });
  };

  return (
    <Modal show={isShown} onClose={onModalClose}>
      <CoworkerControlsWrapper>
        <div tw="flex space-x-4">
          <div tw="grow">
            <IntakeInitInput
              label={'Firstname'}
              errorMessage={'This field is required'}
              hasError={errors['firstname']}
            >
              <Controller
                id="firstname"
                name="firstname"
                control={control}
                defaultValue={currentCoWorker?.firstname ?? ''}
                rules={{
                  validate: stringValidator,
                }}
                render={({ value, onChange }) => (
                  <Input
                    id="firstname"
                    name="firstname"
                    placeholder="Jane"
                    value={value}
                    ariaInvalid={errors['firstname']}
                    onChange={onChange}
                  />
                )}
              />
            </IntakeInitInput>
          </div>
          <div tw="grow">
            <IntakeInitInput
              label={'Lastname'}
              errorMessage={'This field is required'}
              hasError={errors['lastname']}
            >
              <Controller
                id="lastname"
                name="lastname"
                control={control}
                defaultValue={currentCoWorker?.lastname ?? ''}
                rules={{
                  validate: stringValidator,
                }}
                render={({ value, onChange }) => (
                  <Input
                    id="lastname"
                    name="lastname"
                    placeholder="Doe"
                    value={value}
                    ariaInvalid={errors['lastname']}
                    onChange={onChange}
                  />
                )}
              />
            </IntakeInitInput>
          </div>
        </div>
        <div tw="flex space-x-4">
          <div tw="grow">
            <IntakeInitInput
              label={'Email'}
              errorMessage={'This field is required or invalid'}
              hasError={errors['email']}
            >
              <Controller
                id="email"
                name="email"
                control={control}
                defaultValue={currentCoWorker?.email ?? ''}
                rules={{
                  validate: emailValidator,
                }}
                render={({ value, onChange }) => (
                  <Input
                    id="email"
                    name="email"
                    placeholder="jane.doe@sympl.be"
                    value={value}
                    ariaInvalid={errors['email']}
                    onChange={onChange}
                  />
                )}
              />
            </IntakeInitInput>
          </div>

          <div tw="grow">
            <IntakeInitInput
              label={'Function'}
              errorMessage={'This field is required'}
              hasError={errors['function']}
            >
              <Controller
                id="function"
                name="function"
                control={control}
                defaultValue={currentCoWorker?.function ?? ''}
                rules={{
                  validate: stringValidator,
                }}
                render={({ value, onChange }) => (
                  <Input
                    id="function"
                    name="function"
                    placeholder="Recruiter"
                    value={value}
                    ariaInvalid={errors['function']}
                    onChange={onChange}
                  />
                )}
              />
            </IntakeInitInput>
          </div>
          {includeLeadRecruiter && (
            <div tw="self-center">
              <IntakeInitInput
                label={'Lead Recruiter'}
                errorMessage={'This field is required'}
                hasError={errors['isLeadRecruiter']}
              >
                <Controller
                  id="isLeadRecruiter"
                  name="isLeadRecruiter"
                  control={control}
                  defaultValue={currentCoWorker?.isLeadRecruiter ?? false}
                  render={({ value, onChange }) => (
                    <div tw="mx-auto">
                      <Checkbox
                        id="isLeadRecruiter"
                        name="isLeadRecruiter"
                        placeholder="Recruiter"
                        checked={value}
                        ariaInvalid={errors['isLeadRecruiter']}
                        onCheck={onChange}
                      />
                    </div>
                  )}
                />
              </IntakeInitInput>
            </div>
          )}
        </div>

        {!isEditMode &&
          hasRolesEnabled &&
          userHasPermission(PERMISSIONS.MANAGE_USERS, 'brand') && (
            <div tw="w-full">
              <PermissionGrid
                roles={allRoles ?? []}
                nodes={treeState}
                onRoleChange={(nodes: PermissionLevel[]) => {
                  const flattenedRoles = flattenRoles(nodes);
                  setUserRoles(flattenedRoles);
                }}
              />
            </div>
          )}

        <div tw="pt-4">
          <Button
            variant="gray"
            onClick={(e) => onSave(e, userRoles)}
            loading={onSaveLoading}
            stretch
          >
            {isEditMode ? 'Edit' : 'Add'}
          </Button>
        </div>
      </CoworkerControlsWrapper>
    </Modal>
  );
};
const CoworkerControlsWrapper = styled.div(() => [
  tw`rounded-md pt-10 pb-4 space-y-2`,
]);

export default ModalCoWorker;
