import { OperationVariables } from '@apollo/client/core';
import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  QueryHookOptions,
  QueryResult,
  QueryTuple,
} from '@apollo/client/react/types/types';
import {
  useQuery as useApolloQuery,
  useLazyQuery as useApolloLazyQuery,
} from '@apollo/client';
import { getLocalAppSession } from 'utils/storageHelpers';
import { useEffect } from 'react';

/**
 * Will add the current active customer to a custom header
 * @param query
 * @param options
 */
export function useQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & {
    abortController?: AbortController;
  }
): QueryResult<TData, TVariables> & {
  abortController?: AbortController;
  refetching: boolean;
} {
  const customer = getLocalAppSession()?.activeCustomer;
  const abortController = options?.abortController;

  useEffect(() => {
    return () => {
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const context = options?.context;
  const headers = context?.headers;
  const optionsWithAdditionalHeaders = {
    ...options,
    context: {
      ...context,
      headers: {
        ...headers,
        'Active-Customer': customer,
      },
    },
    signal: abortController?.signal,
    notifyOnNetworkStatusChange: true,
  };

  const { data, networkStatus, ...rest } = useApolloQuery<TData, TVariables>(
    query,
    optionsWithAdditionalHeaders
  );
  return {
    data,
    networkStatus,
    abortController,
    refetching: networkStatus === 4,
    ...rest,
  };
}

/**
 * Will add the current active customer to a custom header
 * @param query
 * @param options
 */
export function useLazyQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & {
    abortController?: AbortController;
  }
): QueryTuple<TData, TVariables> & { abortController?: AbortController } {
  const customer = getLocalAppSession()?.activeCustomer;
  const abortController = options?.abortController;

  const context = options?.context;
  const headers = context?.headers;
  const optionsWithAdditionalHeaders = {
    ...options,
    context: {
      ...context,
      headers: {
        ...headers,
        'Active-Customer': customer,
      },
    },
    signal: abortController?.signal,
  };

  return useApolloLazyQuery<TData, TVariables>(
    query,
    optionsWithAdditionalHeaders
  );
}
