import {
  fetchIntakeByHash,
  lockCustomerIntake,
  submitStep,
} from 'api/intake/intakeApi';
import { DEFAULT_INTAKE_STATE_CONFIG } from 'data/defaultIntakeContextData';
import useNavigationContext from 'hooks/context/nav-context';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IntakeFormResults } from 'types/formTypes';
import { IntakeQuestion, StepperSection } from 'types/intakeTypes';
import {
  IntakeCoreContextType,
  IntakeNotification,
  NotificationTypes,
  TIntakeConfig,
} from 'types/intakes/core';
import { IntakeNumberedSectionsSteps } from 'types/intakes/intake';
import { isJsonString } from 'utils/baseHelpers';
import {
  getMissingQuestionsFromQuestionList,
  checkIfStepOptional,
  objectToArray,
} from 'utils/intakeHelpers';

import { normalizedIntake } from 'utils/normalizationHelpers';

export const IntakeCoreContext = createContext<IntakeCoreContextType>(
  {} as IntakeCoreContextType
);

export const IntakeCoreProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { setActiveCustomer } = useNavigationContext();

  const [errors, setErrors] = useState<Error[]>(
    DEFAULT_INTAKE_STATE_CONFIG.errors
  );
  const [isCompleted, setIsCompleted] = useState<boolean>(
    DEFAULT_INTAKE_STATE_CONFIG.isCompleted
  );
  const [hasStarted, setHasStarted] = useState<boolean>(
    DEFAULT_INTAKE_STATE_CONFIG.hasStarted
  );
  const [hasFinished, setHasFinished] = useState<boolean>(
    DEFAULT_INTAKE_STATE_CONFIG.hasFinished
  );
  const [skippedSteps, setSkippedSteps] = useState<number[]>(
    DEFAULT_INTAKE_STATE_CONFIG.skippedSteps
  );

  // current ids
  const [intakeConfig, setIntakeConfig] = useState<TIntakeConfig | null>(
    DEFAULT_INTAKE_STATE_CONFIG.intakeConfig
  );
  const [currentIntakeId, setCurrentIntakeId] = useState<number>(
    DEFAULT_INTAKE_STATE_CONFIG.currentIntakeId
  );
  const [currentSectionId, setCurrentSectionId] = useState<number>(
    DEFAULT_INTAKE_STATE_CONFIG.currentSectionId
  );
  const [currentStepId, setCurrentStepId] = useState<number>(
    DEFAULT_INTAKE_STATE_CONFIG.currentStepId
  );

  // current state
  const [currentIntakeHash, setCurrentIntakeHash] = useState<string | null>(
    DEFAULT_INTAKE_STATE_CONFIG.currentIntakeHash
  );

  // adds sections to steps config
  const _modifyIntakeConfig = (intakeConfig: any) => {
    const modifiedIntakeConfig = intakeConfig;
    const { sections: intakeSections, steps: intakeSteps } =
      modifiedIntakeConfig;

    // add sections to steps
    Object.keys(intakeSections).forEach((sectionKey) => {
      const sectionObj = intakeSections[sectionKey];
      const sectionSteps = intakeSections[sectionKey].steps as number[];

      // [NEED UPDATE!]
      if (sectionSteps) {
        sectionSteps.forEach((stepId) => {
          intakeSteps[stepId].linked_section = sectionObj;
        });
      }
    });

    const toReturn = {
      ...modifiedIntakeConfig,
      steps: intakeSteps,
    };

    return toReturn;
  };

  const _getQuestionsByIds = (
    ids: number[],
    questions: IntakeQuestion[]
  ): IntakeQuestion[] => {
    return ids
      .map((id) => questions.find((q) => q.id === id))
      .filter((q) => q !== undefined) as IntakeQuestion[];
  };

  /**
   * this answers the questions and populates the following keys
   * value - answer
   * annotation - feedback from customers on the field
   * @param resFromAPI
   * @returns
   */
  const _updateAnswers = (
    resFromAPI: Pick<IntakeFormResults, 'step' | 'questions' | 'completed'>
  ) => {
    if (!intakeConfig?.questions) return {};

    const { questions } = resFromAPI;
    const stateQuestions = {
      ...intakeConfig.questions,
    };

    questions.forEach((q) => {
      const { value, id, annotation } = q;
      let finalValue: any = value as string;
      if (!stateQuestions[`${id}`]) return;

      // not answered set to empty string
      if (!value) {
        finalValue = '' as string;
      }

      // if value is json string
      if (value && typeof value === 'string' && isJsonString(value)) {
        finalValue = JSON.parse(value);
      } else {
        if (value?.constructor === Array) {
          finalValue = value as string[];
        }
        if (!value) {
          finalValue = '' as string;
        }
      }

      stateQuestions[`${id}`] = {
        ...stateQuestions[`${id}`],
        value: finalValue,
        annotation,
      };
    });

    return stateQuestions;
  };

  useEffect(() => {
    if (!currentIntakeId) return;
    const customerId = intakeConfig?.intakes[currentIntakeId]?.customer_id;
    if (customerId) setActiveCustomer(customerId);
  }, [intakeConfig, currentIntakeId]);

  const currentActiveIntake = useMemo(() => {
    return (
      (intakeConfig?.intakes[
        currentIntakeId
      ] as unknown as IntakeNumberedSectionsSteps) ?? null
    );
  }, [intakeConfig, currentIntakeId]);

  const currentActiveSection = useMemo(() => {
    if (!currentSectionId) return null;
    return intakeConfig?.sections[currentSectionId];
  }, [intakeConfig, intakeConfig?.sections, currentSectionId]);

  const currentActiveStep = useMemo(() => {
    if (!currentStepId) return null;
    return intakeConfig?.steps[currentStepId];
  }, [intakeConfig, intakeConfig?.steps, currentStepId]);

  const currentIntakeProgress = useMemo(() => {
    if (!intakeConfig?.questions) return 0;

    const arrQuestions = objectToArray(intakeConfig.questions);
    const missedAnswers =
      getMissingQuestionsFromQuestionList(arrQuestions).length;
    const totalRequiredQuestions = arrQuestions.filter(
      (q) => q.required
    ).length;
    return Math.round(100 - (missedAnswers / totalRequiredQuestions) * 100);
  }, [intakeConfig, intakeConfig?.questions, currentStepId]);

  const currentIntakeStepperData = useMemo(() => {
    if (
      !currentActiveIntake ||
      !intakeConfig?.steps ||
      !intakeConfig?.questions
    )
      return [];

    const { sections, steps, questions } = intakeConfig;
    const sectionKeys = currentActiveIntake?.sections;
    const stepperSections = sectionKeys.map((sKey) => {
      const sectionObj = sections[sKey];
      return {
        completed: false,
        name: sectionObj?.name ?? '',
        id: sectionObj?.id ?? 0,
        steps: sectionObj.steps.map((step) => {
          const matchedStep =
            objectToArray(steps).find((s) => s.id === step) ?? null;
          const questionsIds = matchedStep.questions ?? [];
          const arrQuestions = objectToArray(questions);
          const missedAnswers =
            getMissingQuestionsFromQuestionList(
              _getQuestionsByIds(questionsIds, arrQuestions) ?? []
            ) ?? [];
          const isStepOptional = checkIfStepOptional(
            _getQuestionsByIds(questionsIds, arrQuestions) ?? []
          );
          return {
            id: matchedStep?.id ?? 0,
            name: matchedStep?.name ?? '',
            remainingQuestionCount: missedAnswers.length ?? 0,
            isStepOptional,
          };
        }),
      };
    });
    return stepperSections.map(
      (section) =>
        ({
          ...section,
          completed:
            section.steps.filter((step) => step.remainingQuestionCount > 0)
              .length === 0,
        } as StepperSection)
    );
  }, [
    currentActiveIntake,
    intakeConfig,
    intakeConfig?.sections,
    intakeConfig?.steps,
    intakeConfig?.questions,
  ]);

  // loading states
  const [loading, setLoading] = useState<boolean>(
    DEFAULT_INTAKE_STATE_CONFIG.loading
  );

  // files
  const [filesBeingUploaded, setFilesBeingUploaded] = useState<string[]>(
    DEFAULT_INTAKE_STATE_CONFIG.filesBeingUploaded
  );
  // notification
  const [notifications, setNotifications] = useState<IntakeNotification[]>(
    DEFAULT_INTAKE_STATE_CONFIG.notifications
  );

  // handlers
  const handleSetIntakeHash = (hash: string) => {
    setCurrentIntakeHash(hash);
  };
  const handleSetStepId = useCallback(
    (id: number) => {
      setCurrentStepId(id);
    },
    [currentStepId]
  );
  const handleSetIntakeId = (id: number) => {
    setCurrentIntakeId(id);
  };
  const handleSetSectionId = (id: number) => {
    setCurrentSectionId(id);
  };
  const handleFilesBeingUploaded = (files: string[]) => {
    setFilesBeingUploaded(files);
  };
  const handleAddNotification = (notification: IntakeNotification) => {
    setNotifications((prev) => [...prev, notification]);
  };
  const handleRemoveNotification = (notification: IntakeNotification) => {
    setNotifications((prev) =>
      prev.filter((n) => n.message !== notification.message)
    );
  };
  const handleSetSkippedSteps = (stepId: number) => {
    setSkippedSteps((prev) => [...prev, stepId]);
  };
  const handleStartIntake = () => {
    setHasStarted(true);
  };

  const handleStepCompleted = (stepId: number) => {
    setIntakeConfig((prev) => {
      if (!prev || !prev.steps || !prev.steps[stepId]) return prev;
      return {
        ...prev,
        steps: {
          ...prev.steps,
          [stepId]: {
            ...prev.steps[stepId],
            completed: true,
          },
        },
      };
    });
  };
  const handleSetIntakeConfig = (intake: any) => {
    setIntakeConfig(intake);
  };

  // APIS
  const fetchIntake = async (intakeHash: string) => {
    setLoading(true);
    try {
      const data = await fetchIntakeByHash(intakeHash);
      const intakeConfig = normalizedIntake(data).entities;

      if (data.completed) setHasFinished(true);

      // no sections === error
      if (!data.sections || data.sections.length <= 0) {
        const errObj = new Error('The requested intake template is invalid');
        setErrors((prev) => [...prev, errObj]);
        return errObj;
      }

      const modified = _modifyIntakeConfig(intakeConfig);
      setIntakeConfig(modified);
      setLoading(false);
      return modified;
    } catch (error) {
      setLoading(false);
      return error as Error;
    }
  };

  const lockIntake = async (intakeId: number) => {
    try {
      const res = await lockCustomerIntake(intakeId);
      setIsCompleted(true);
      setHasFinished(true);
      return res;
    } catch (error) {
      setNotifications((prev) => [
        ...prev,
        {
          type: NotificationTypes.ERROR,
          message: 'We could not complete your intake, please try again later.',
        },
      ]);

      return error as Error;
    }
  };
  const submitIntakeStep = async (stepData: IntakeFormResults) => {
    setLoading(true);
    try {
      const filteredData = {
        ...stepData,
        questions: stepData.questions.filter(
          ({ id }) => id !== null && !Number.isNaN(id)
        ),
      };
      const res = await submitStep(filteredData);
      const answeredQuestions = _updateAnswers(res);
      const newConfig = {
        ...intakeConfig,
        questions: answeredQuestions as unknown as any,
      } as any;
      setIntakeConfig(newConfig);
      setLoading(false);
      return res;
    } catch (error) {
      setErrors((prev) => [...prev, error as Error]);
      setLoading(false);
      return error as Error;
    }
  };

  return (
    <IntakeCoreContext.Provider
      value={{
        errors,

        // current intake config
        intakeConfig,

        // step checks
        isCompleted,

        // current ids
        currentIntakeId,
        currentSectionId,
        currentStepId,

        // currentObjects
        currentActiveSection,
        currentActiveStep,
        currentActiveIntake,

        // current state
        hasStarted,
        hasFinished,
        currentIntakeHash,

        // loading states
        loading,
        // files
        filesBeingUploaded,

        // notification
        notifications,
        skippedSteps,

        // handlers
        handleSetIntakeHash,
        handleSetStepId,
        handleSetIntakeId,
        handleSetSectionId,
        handleFilesBeingUploaded,
        handleAddNotification,
        handleRemoveNotification,
        handleSetSkippedSteps,
        handleStartIntake,
        handleStepCompleted,
        handleSetIntakeConfig,
        // API
        fetchIntake,
        lockIntake,
        submitIntakeStep,
        currentIntakeProgress,
        currentIntakeStepperData,
      }}
    >
      {children}
    </IntakeCoreContext.Provider>
  );
};
