import { useQuery } from 'hooks/sympl-query';
import { useMutation } from 'hooks/sympl-mutation';
import 'twin.macro';

import Button from 'components/button/Button';
import { INVITE_COLLEAGUE } from 'graphql/customers/mutations';
import { GET_CUSTOMER_USERS } from 'graphql/customers/queries';
import useAppSession from 'hooks/session';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Plus, FloppyDisk } from '@phosphor-icons/react';
import { Customer } from 'types/customer/types';
import Container from 'components/container/Container';
import ContainerHeader from 'components/container/container-header/ContainerHeader';
import ColleaguesList from './ColleaguesList';
import { Title2 } from 'components/typography/Typography';
import { RESEND_INVITE_COLLEAGUE, UNLINK_USER } from 'graphql/users/mutations';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import { User } from 'types/userTypes';
import ModalCoWorker from 'components/form/modal/ModalCoWorker';
import PermissionWrapper from '../../../components/permissions/permissionWrapper/PermissionWrapper';
import { PERMISSIONS } from 'context/PermissionContext';
import usePermissionContext from 'hooks/context/permission-context';
import { UPDATE_USER_ROLES } from 'graphql/users/mutations';
import useNavigationContext from 'hooks/context/nav-context';

type InviteColleague = 'firstname' | 'lastname' | 'email' | 'function';

interface CustomerColleagues extends Customer {
  users: User[];
}

interface InviteColleagueData {
  email: string;
  first_name: string;
  last_name: string;
  job_position: string;
  roles?: RoleData[];
}

interface InviteColleaguePayload {
  input: InviteColleagueData;
}

export type ChangedRole = {
  roleName: string;
  modelId: number;
  modelType: string;
};

export type RoleData = {
  role_id: number;
  role_name: string;
  model_id: number;
  model_type: string;
};

type ChangedUserRole = {
  user_id: number;
  roles: {
    role_id: number;
    role_name: string;
    model_id: number;
    model_type: string;
  }[];
};

const UsersConfig: React.FC = () => {
  const session = useAppSession();
  const activeCustomer = session?.activeCustomer;
  const { setUserManagers, refetchPermissions } = usePermissionContext();
  const { hasRolesEnabled } = useNavigationContext();

  const formMethods = useForm({
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      function: '',
    },
  });
  const { handleSubmit, reset: resetForm } = formMethods;
  const { addToast } = useToastNotifications();

  const [formIsShown, setFormIsShown] = useState(false);
  const [changedRoles, setChangedRoles] = React.useState<ChangedUserRole[]>([]);

  const {
    data: customerColleagues,
    refetch: refetchColleagues,
    loading: loadingUsers,
  } = useQuery<
    { customer: CustomerColleagues },
    { customerId: number; include: string }
  >(GET_CUSTOMER_USERS, {
    skip: !activeCustomer,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      customerId: activeCustomer as number,
      include: 'users,users.roles',
    },
  });

  const userManagers = useMemo(() => {
    const managers = customerColleagues?.customer.users.filter((user) =>
      user.roles.some((role) => role.roles.includes('user-manager'))
    );
    return managers?.map((manager) => manager.id);
  }, [customerColleagues]);

  useEffect(() => {
    setUserManagers(userManagers ?? []);
  }, [userManagers]);

  const [inviteColleague, { loading: inviteColleagueLoading }] = useMutation<
    undefined,
    InviteColleaguePayload
  >(INVITE_COLLEAGUE);

  const [resendInviteColleague, { loading: resendingInvite }] = useMutation<
    {},
    { userId: number }
  >(RESEND_INVITE_COLLEAGUE);

  const [updateUserRoles, { loading }] = useMutation<
    {},
    {
      input: {
        user_roles: {
          user_id: number;
          roles: {
            role_id: number;
            role_name: string;
            model_id: number;
            model_type: string;
          }[];
        }[];
      };
    }
  >(UPDATE_USER_ROLES);

  const [unLinkUser] = useMutation<{}, { userId: number }>(UNLINK_USER);

  const submitHandler = async (
    data: {
      [K in InviteColleague]?: string;
    },
    roles: RoleData[]
  ) => {
    await inviteColleague({
      variables: {
        input: {
          email: data['email']?.toString() ?? '',
          first_name: data['firstname']?.toString() ?? '',
          last_name: data['lastname']?.toString() ?? '',
          job_position: data['function']?.toString() ?? '',
          roles: roles,
        },
      },
    });
    addToast({
      type: ToastTypes.SUCCESS,
      description: `You have successfully invited ${data['firstname']} ${data['lastname']}`,
    });
    resetForm();
    refetchColleagues();
    setFormIsShown(false);
  };

  const resendInvite = (colleague: User) => {
    resendInviteColleague({
      variables: {
        userId: colleague.id,
      },
    })
      .then(() => {
        addToast({
          type: ToastTypes.SUCCESS,
          description:
            'We have sent an invitation mail to' +
            ` ${colleague.firstname} ${colleague.lastname}`,
        });
      })
      .catch(() => {
        addToast({
          description:
            'We were unable to sent an invitation mail to' +
            ` ${colleague.firstname} ${colleague.lastname}` +
            ', please try again. Please contact us if this problem persists!',
          type: ToastTypes.ERROR,
        });
      });
  };

  const unLinkColleague = async (colleague: User) => {
    await unLinkUser({
      variables: {
        userId: colleague.id,
      },
    });

    refetchColleagues();
  };

  const saveRoles = () => {
    updateUserRoles({
      variables: {
        input: {
          user_roles: changedRoles,
        },
      },
    }).then(() => {
      refetchPermissions();
      addToast({
        title: 'User has been updated',
        description: "You have successfully updated the user's roles",
        type: ToastTypes.SUCCESS,
      });
    });
  };

  const handleRoleChange = (userId: number, roleData: RoleData[]) => {
    setChangedRoles((prev) => {
      const userIndex = prev.findIndex((user) => user.user_id === userId);
      if (userIndex === -1) {
        return [
          ...prev,
          {
            user_id: userId,
            roles: roleData,
          },
        ];
      }
      return [
        ...prev.slice(0, userIndex),
        {
          user_id: userId,
          roles: roleData,
        },
        ...prev.slice(userIndex + 1),
      ];
    });
  };

  return (
    <Container>
      <ContainerHeader>
        <div tw="flex flex-row justify-between">
          <Title2 mb={0}>Users</Title2>
          {/* TODO: Make this brand level permissions - 22/01/2025 */}
          <PermissionWrapper
            permission={PERMISSIONS.MANAGE_USERS}
            on="customer"
          >
            <div tw="flex gap-4">
              <Button
                onClick={() => setFormIsShown(true)}
                icon={<Plus weight="bold" />}
              >
                Add user
              </Button>

              {hasRolesEnabled && (
                <Button
                  loading={loading}
                  onClick={saveRoles}
                  icon={<FloppyDisk weight="bold" />}
                >
                  Save user roles
                </Button>
              )}
            </div>
          </PermissionWrapper>
        </div>
      </ContainerHeader>
      <div tw="flex w-full flex-row flex-wrap gap-5 mt-4">
        {!loadingUsers && (
          <div tw="flex w-full flex-col">
            <ColleaguesList
              onResend={resendInvite}
              onUnlink={unLinkColleague}
              colleagues={customerColleagues?.customer.users ?? []}
              loading={resendingInvite}
              onUpdateRoles={handleRoleChange}
            />
          </div>
        )}
        <div tw="border"></div>
        <FormProvider {...formMethods}>
          <form autoComplete="on" tw="w-full">
            <ModalCoWorker
              isShown={formIsShown}
              onModalClose={() => setFormIsShown(false)}
              isEditMode={false}
              onSave={(e, newRoles) =>
                handleSubmit((data) => submitHandler(data, newRoles))()
              }
              onSaveLoading={inviteColleagueLoading}
              includeLeadRecruiter={false}
            />
          </form>
        </FormProvider>
      </div>
    </Container>
  );
};

export default UsersConfig;
