import 'twin.macro';
import React from 'react';

import AppPage from 'components/page/app-page/AppPage';
import GeneralSettings from './general/GeneralSettings';
import SubscriptionSettings from './subscriptions/SubscriptionSettings';
import GuardedRoute from 'routing/GuardedRoute';
import { RouteGuards, Routes as RouteTypes } from 'types/routeTypes';
import InvoicingSettings from './invoicing/InvoicingSettings';
import MediaSettings from './media/MediaSettings';
import UsersConfig from './users/UsersConfig';
import AccountSettings from './account/AccountSettings';
import TemplatesRouter from 'views/templates/TemplatesRouter';
import IntegrationSettings from './integrations/IntegrationSettings';
import { IntegrationSucceeded } from './integrations/IntegrationSucceeded';
import { BrandsSettings } from './brands/BrandsSettings';
import BalanceSettings from './balance/BalanceSettings';
import { Routes, Route, useNavigate, Navigate, Outlet } from 'react-router-dom';
import usePermissionContext from 'hooks/context/permission-context';
import { PERMISSIONS } from 'context/PermissionContext';

type Tab = {
  item: string;
  path: string;
  element: React.ReactNode;
  route: string;
  allowedPermissions?: string[];
  requiredPermissionLevel?: 'brand' | 'customer' | 'vacancy';
};

const Settings: React.FC = () => {
  const { currentVacPermissions, userHasPermission } = usePermissionContext();
  const tabs: Tab[] = [
    {
      item: 'General',
      path: 'general',
      element: <GeneralSettings />,
      route: RouteTypes.GENERAL_SETTINGS,
      allowedPermissions: [PERMISSIONS.MANAGE_FINANCES],
      requiredPermissionLevel: 'brand',
    },
    {
      item: 'Account',
      path: 'account',
      element: <AccountSettings />,
      route: RouteTypes.ACCOUNT_SETTINGS,
    },
    {
      item: 'Users',
      path: 'users',
      element: <UsersConfig />,
      route: RouteTypes.USER_SETTINGS,
      allowedPermissions: [PERMISSIONS.MANAGE_USERS],
      requiredPermissionLevel: 'brand',
    },
    {
      item: 'Brands',
      path: 'brands',
      element: <BrandsSettings />,
      route: RouteTypes.BRAND_SETTINGS,
      allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
      requiredPermissionLevel: 'brand',
    },
    {
      item: 'Integrations',
      path: 'integrations',
      element: <IntegrationSettings />,
      route: RouteTypes.INTEGRATION_SETTINGS,
      allowedPermissions: [PERMISSIONS.MANAGE_FINANCES],
      requiredPermissionLevel: 'brand',
    },
    {
      item: 'Subscription',
      path: 'subscription',
      element: <SubscriptionSettings />,
      route: RouteTypes.SUBSCRIPTION_SETTINGS,
      allowedPermissions: [PERMISSIONS.MANAGE_FINANCES],
      requiredPermissionLevel: 'brand',
    },
    {
      item: 'Invoices',
      path: 'invoicing',
      element: <InvoicingSettings />,
      route: RouteTypes.INVOICING_SETTINGS,
      allowedPermissions: [PERMISSIONS.MANAGE_FINANCES],
      requiredPermissionLevel: 'brand',
    },
    {
      item: 'Media',
      path: 'media',
      element: <MediaSettings />,
      route: RouteTypes.MEDIA_SETTINGS,
      allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
      requiredPermissionLevel: 'vacancy',
    },
    {
      item: 'Templates',
      path: 'templates',
      element: <TemplatesRouter />,
      route: RouteTypes.TEMPLATE_SETTINGS,
      allowedPermissions: [
        PERMISSIONS.MANAGE_CANDIDATES,
        PERMISSIONS.MANAGE_CAMPAIGNS,
      ],
      requiredPermissionLevel: 'vacancy',
    },
    {
      item: 'Balance',
      path: 'balance',
      route: RouteTypes.BALANCE_SETTINGS,
      element: <BalanceSettings />,
      allowedPermissions: [PERMISSIONS.MANAGE_FINANCES],
      requiredPermissionLevel: 'brand',
    },
  ];

  const navigate = useNavigate();

  const filteredtabs = React.useMemo(
    () =>
      tabs.filter(
        (tab) =>
          !tab.allowedPermissions ||
          tab.allowedPermissions.some((p) => userHasPermission(p, 'brand'))
      ),
    [tabs, currentVacPermissions]
  );

  const activeTabIndex = React.useMemo(
    () =>
      filteredtabs.findIndex((tab) => location.pathname.includes(tab.route)) ??
      0,
    [tabs]
  );

  return (
    <AppPage
      heading="Settings"
      tabs={filteredtabs.map(({ item }) => item)}
      activeTabIndex={activeTabIndex}
      onTabChange={(index) => navigate(filteredtabs[index].route)}
      allowPageWithoutSubscription
      allowPageWithoutVacancy
    >
      <Routes>
        {tabs.map((tab) => (
          <Route
            key={tab.path}
            path={tab.path}
            element={
              <GuardedRoute
                guard={RouteGuards.AUTHENTICATED}
                allowedPermissions={tab.allowedPermissions}
                requiredPermissionLevel={tab.requiredPermissionLevel}
              >
                {tab.element}
              </GuardedRoute>
            }
          />
        ))}
        <Route
          path={'integrations-succeeded'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <IntegrationSucceeded />
            </GuardedRoute>
          }
        />
        <Route
          path={'templates/*'}
          element={
            <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
              <TemplatesRouter />
            </GuardedRoute>
          }
        />
        <Route
          path="*"
          element={<Navigate replace to={filteredtabs[0].route} />}
        />
      </Routes>
      <Outlet />
    </AppPage>
  );
};

export default Settings;
