import { IntakeFormResults } from 'types/formTypes';
import { Intake, UserIntake } from 'types/intakeTypes';
import { intakeApiError } from 'utils/apiHelpers';
import { get, post } from '../api';

export const fetchIntakes = async (): Promise<UserIntake[]> => {
  try {
    const { data } = await get<any>('/v4/intakes');
    return data.data;
  } catch (error: any) {
    throw intakeApiError(error);
  }
};

export const fetchIntakeByHash = async (hash: string): Promise<Intake> => {
  try {
    const { data } = await get<Intake>(`/v4/intakes/${hash}`);
    return data.data;
  } catch (error: any) {
    throw intakeApiError(error);
  }
};

export const submitStep = async (
  formData: IntakeFormResults
): Promise<Pick<IntakeFormResults, 'step' | 'questions' | 'completed'>> => {
  try {
    const result = (await post<Pick<IntakeFormResults, 'step' | 'questions'>>(
      `/v4/intakes/answers`,
      formData
    )) as any;

    return {
      step: result.data.data.step,
      questions: result.data.data.questions,
      completed: result.data.data.intake_completed,
    };
  } catch (error: any) {
    throw intakeApiError(error);
  }
};

export const lockCustomerIntake = async (intakeId: number): Promise<number> => {
  try {
    const response = (await post<string[]>(
      `/v4/intakes/${intakeId}/completed`,
      {}
    )) as any;

    return response.data.data;
  } catch (error: any) {
    throw intakeApiError(error);
  }
};
