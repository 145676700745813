export const models = Object.freeze({
  CUSTOMER: 'App\\Sympl\\Models\\Customer',
  BRAND: 'App\\Sympl\\Models\\Brand',
  VACANCY: 'App\\Sympl\\Models\\Vacancy',
});

export type PermissionLevel = {
  id: number;
  name: string;
  model_type: (typeof models)[keyof typeof models];
  roles: string[];
  children?: PermissionLevel[];
};
