import tw, { styled } from 'twin.macro';
import React from 'react';
import { FilmStrip, Image } from '@phosphor-icons/react';

import { Ad, AdStatus } from 'types/adTypes';
import { numberToDecimalNotation } from 'utils/baseHelpers';
import Badge from 'components/badge/Badge';
import { Body } from 'components/typography/Typography';
import ChannelIcon from 'components/channel-icon/ChannelIcon';

const ICON_COLOR = 'rgba(107, 114, 128, 1)';

const AdListItem: React.FC<{
  ad: Ad;
  showVacancyPageMetrics?: boolean;
}> = ({ ad, showVacancyPageMetrics = false }) => {
  const img = ad.file_type?.startsWith('Image') ? ad.stencil : ad.thumbnail_url;
  const type = ad.file_type ?? 'Ad';
  const status = ad.status ?? 'PAUSED';

  const isVideo = type.startsWith('Video');

  const { impressions, clicks, visits, leads, spend, applications } =
    ad.adMetric || {};

  const getAdStatusColor = (status: AdStatus) => {
    switch (status) {
      case 'ACTIVE':
      case 'COMPLETED':
        return 'green';
      case 'ARCHIVED':
        return 'gray';
      case 'PAUSED':
      case 'REVISION':
      case 'INACTIVE':
        return 'yellow';
      default:
        return 'green';
    }
  };

  return (
    <tr>
      {/* Ad Type */}
      <TableCell isAdType data-table-cell="Ad Type">
        {img ? (
          <Preview alt="Ad" loading="lazy" src={img} />
        ) : (
          <PreviewAlt>
            {isVideo ? (
              <FilmStrip
                weight="bold"
                size={20}
                style={{ color: ICON_COLOR }}
              />
            ) : (
              <Image weight="bold" size={20} style={{ color: ICON_COLOR }} />
            )}
          </PreviewAlt>
        )}
        <span tw="text-gray-400 text-sm ml-4 capitalize">{type}</span>
      </TableCell>

      {/* Published on */}
      <TableCell data-table-cell="Published on">
        <div tw="flex flex-row items-center text-gray-600">
          <ChannelIcon channel={ad.channel} grayScale />
          <span tw="ml-2 text-sm capitalize">{ad.placement}</span>
        </div>
      </TableCell>

      {/* Impressions */}
      <TableCell isMetric data-table-cell="Impressions">
        <span>{numberToDecimalNotation(impressions ?? 0)}</span>
      </TableCell>

      {/* Clicks */}
      {!showVacancyPageMetrics && (
        <TableCell isMetric data-table-cell="Clicks">
          <span>{numberToDecimalNotation(clicks ?? 0)}</span>
        </TableCell>
      )}

      {/* Visits */}
      {showVacancyPageMetrics && (
        <TableCell isMetric data-table-cell="Visits">
          <span>{numberToDecimalNotation(visits ?? 0)}</span>
        </TableCell>
      )}

      {/* Leads */}
      {showVacancyPageMetrics && (
        <TableCell isMetric data-table-cell="Leads">
          <span>{leads ? numberToDecimalNotation(leads) : '-'}</span>
        </TableCell>
      )}

      {/* Applications */}
      {showVacancyPageMetrics && (
        <TableCell isMetric data-table-cell="Applications">
          <span>{applications !== 0 ? applications : ''}</span>
        </TableCell>
      )}

      {/* Spent */}
      <TableCell isMetric data-table-cell="Spent">
        {spend ? <Body isBlock={false}>€ {spend}</Body> : ''}
      </TableCell>

      {/* Status */}
      <TableCell data-table-cell="Status">
        <Badge label={status} color={getAdStatusColor(status)} />
      </TableCell>
    </tr>
  );
};

const Preview = styled.img(
  tw`rounded-md object-cover h-11 w-11 min-h-[2.75rem] min-w-[2.75rem]`
);

const PreviewAlt = styled.div(
  tw`flex justify-center items-center bg-gray-100 h-11 w-11 rounded-lg`
);

const TableCell = styled.td<{
  isMetric?: boolean;
  isTextGray?: boolean;
  isAdType?: boolean;
}>`
  ${tw`
    px-6 
    py-4 
    whitespace-nowrap
  `}
  ${({ isMetric }) => isMetric && tw`font-bold text-gray-700`}
  ${({ isTextGray }) => isTextGray && tw`text-gray-600`}
  ${({ isAdType }) => isAdType && tw`flex items-center`}
`;

export default AdListItem;
