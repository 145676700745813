import tw from 'twin.macro';
import React, { useMemo, useState } from 'react';
import ResourceList from 'components/upload/preview/preview-list/ResourceList';
import useMetaQuery from 'hooks/metaQuery';
import {
  PaginatedResourceRequestPayload,
  PaginatedResources,
} from 'components/pickers/resource-picker/ResourceTypes';
import { useMutation } from 'hooks/sympl-mutation';
import { GET_CUSTOMER_RESOURCES } from 'graphql/customer-resources/queries';
import { DELETE_CUSTOMER_RESOURCE } from 'graphql/customer-resources/mutations';
import Button from 'components/button/Button';
import { DownloadSimple } from '@phosphor-icons/react';
import { CustomerResource } from 'types/apiTypes';
import { downloadFile } from 'utils/fileHelpers';
import { Body } from 'components/typography/Typography';
import useNavigationContext from 'hooks/context/nav-context';

type ResourceType = 'images' | 'videos' | 'logos' | 'documents';

interface deleteCustomerResourcePayload {
  res_id: number;
}

const PAGINATION_PAGE_SIZE = 15;

const MediaSettings: React.FC = () => {
  const { activeCustomer } = useNavigationContext();
  const [activePage, setActivePage] = useState({
    images: 1,
    videos: 1,
    logos: 1,
    documents: 1,
  });

  const fetchMoreFiles = async (page: number, type: ResourceType) => {
    setActivePage((prev) => ({ ...prev, [type]: page }));
    try {
      switch (type) {
        case 'images':
          await fetchMoreImages({
            variables: {
              pageSize: PAGINATION_PAGE_SIZE,
              currentPage: page,
              types: 'image',
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return fetchMoreResult;
            },
          });
          break;
        case 'videos':
          await fetchMoreVideos({
            variables: {
              pageSize: PAGINATION_PAGE_SIZE,
              currentPage: page,
              types: 'video',
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return fetchMoreResult;
            },
          });
          break;
        case 'logos':
          await fetchMoreLogos({
            variables: {
              pageSize: PAGINATION_PAGE_SIZE,
              currentPage: page,
              types: 'logo',
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return fetchMoreResult;
            },
          });
          break;
        case 'documents':
          await fetchMoreDocuments({
            variables: {
              pageSize: PAGINATION_PAGE_SIZE,
              currentPage: page,
              types: 'document',
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return fetchMoreResult;
            },
          });
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
      return Promise.reject();
    } finally {
      return Promise.resolve();
    }
  };

  const refetchResources = () => {
    refetchImages();
    refetchVideos();
    refetchLogos();
    refetchDocuments();
  };

  const {
    data: imagesData,
    refetch: refetchImages,
    fetchMore: fetchMoreImages,
  } = useMetaQuery<PaginatedResources, PaginatedResourceRequestPayload>(
    GET_CUSTOMER_RESOURCES,
    {
      variables: {
        pageSize: PAGINATION_PAGE_SIZE,
        currentPage: activePage.images ?? 1,
        types: 'image',
      },
      fetchPolicy: 'network-only',
      skip: !activeCustomer,
    }
  );

  const {
    data: videosData,
    refetch: refetchVideos,
    fetchMore: fetchMoreVideos,
  } = useMetaQuery<PaginatedResources, PaginatedResourceRequestPayload>(
    GET_CUSTOMER_RESOURCES,
    {
      variables: {
        pageSize: PAGINATION_PAGE_SIZE,
        currentPage: activePage.videos ?? 1,
        types: 'video',
      },
      fetchPolicy: 'network-only',
      skip: !activeCustomer,
    }
  );

  const {
    data: logosData,
    refetch: refetchLogos,
    fetchMore: fetchMoreLogos,
  } = useMetaQuery<PaginatedResources, PaginatedResourceRequestPayload>(
    GET_CUSTOMER_RESOURCES,
    {
      variables: {
        pageSize: PAGINATION_PAGE_SIZE,
        currentPage: activePage.logos ?? 1,
        types: 'logo',
      },
      fetchPolicy: 'network-only',
      skip: !activeCustomer,
    }
  );

  const {
    data: documentsData,
    refetch: refetchDocuments,
    fetchMore: fetchMoreDocuments,
  } = useMetaQuery<PaginatedResources, PaginatedResourceRequestPayload>(
    GET_CUSTOMER_RESOURCES,
    {
      variables: {
        pageSize: PAGINATION_PAGE_SIZE,
        currentPage: activePage.documents ?? 1,
        types: 'document',
      },
      fetchPolicy: 'network-only',
      skip: !activeCustomer,
    }
  );

  const customerhasMedia = useMemo(() => {
    return (
      imagesData?.customerResources?.length ||
      videosData?.customerResources?.length ||
      logosData?.customerResources?.length ||
      documentsData?.customerResources?.length
    );
  }, [imagesData, videosData, logosData, documentsData]);

  const [deleteResource] = useMutation<
    undefined,
    deleteCustomerResourcePayload
  >(DELETE_CUSTOMER_RESOURCE, {
    onCompleted: refetchResources,
  });

  const deleteHandlerFor = (resourceIdx?: number) => {
    if (typeof resourceIdx !== 'number') return;

    deleteResource({
      variables: {
        res_id: resourceIdx,
      },
    });
  };

  const handleDownloadAll = (resources: CustomerResource[]) => {
    resources.forEach(({ path }) => downloadFile(path));
  };

  return customerhasMedia ? (
    <div tw="grid 2xl:grid-cols-2 gap-6">
      {[imagesData, videosData, logosData, documentsData].map((data, idx) => {
        const fileType = ['images', 'videos', 'logos', 'documents'][
          idx
        ] as ResourceType;
        const resources = data?.customerResources ?? [];
        return (
          <div key={fileType} tw="flex flex-col justify-between">
            <div>
              <div tw="flex justify-between items-center">
                <span tw="font-bold text-xs text-gray-400 tracking-wider capitalize">
                  {fileType}
                </span>
                <Button
                  variant="inverted"
                  size="small"
                  onClick={() => handleDownloadAll(resources)}
                  icon={<DownloadSimple weight="bold" />}
                >
                  Download all
                </Button>
              </div>

              <ResourceList
                resources={resources}
                allowDownload={true}
                allowDelete={true}
                onDelete={deleteHandlerFor}
              />
            </div>
            <div tw="flex gap-4">
              <Button
                customStyle={tw`mt-4 px-5 py-1.5`}
                variant="primary"
                size="small"
                disabled={activePage[fileType] === 1}
                onClick={() =>
                  setActivePage((prev) => {
                    const newPage = Math.max(prev[fileType] - 1, 1);
                    fetchMoreFiles(newPage, fileType);
                    return { ...prev, [fileType]: newPage };
                  })
                }
              >
                Previous Page
              </Button>

              <Button
                customStyle={tw`mt-4 px-5 py-1.5`}
                variant="primary"
                size="small"
                disabled={resources.length < PAGINATION_PAGE_SIZE}
                onClick={() =>
                  setActivePage((prev) => {
                    const newPage = prev[fileType] + 1;
                    fetchMoreFiles(newPage, fileType);
                    return { ...prev, [fileType]: newPage };
                  })
                }
              >
                Next Page
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <Body tw="text-center font-medium text-lg">
      You have no media items yet. <br /> Get started by uploading some when
      making your first campaign
    </Body>
  );
};

export default MediaSettings;
