import { Role } from 'context/PermissionContext';
import React, { useMemo, useState } from 'react';
import 'twin.macro';
import AccordionNode from './AccordionNode';
import { models, PermissionLevel } from 'types/permissionTypes';
import { Warning } from '@phosphor-icons/react';
import usePermissionContext from 'hooks/context/permission-context';

interface PermissionGridProps {
  roles: Role[];
  nodes?: PermissionLevel[];
  userId?: number;
  onRoleChange?: (nodes: PermissionLevel[]) => void;
}

const PermissionGrid: React.FC<PermissionGridProps> = ({
  roles,
  nodes = [],
  onRoleChange,
  userId,
}) => {
  const [treeState, setTreeState] = useState<PermissionLevel[]>(nodes);
  const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);
  const { allRoles } = usePermissionContext();

  React.useEffect(() => {
    onRoleChange?.(treeState);
  }, [treeState]);

  const rolesIncludeCustomer = useMemo(
    () => treeState.some((node) => node.roles.length > 0),
    [treeState]
  );

  const rolesIncludeBrand = useMemo(
    () =>
      treeState.some((node) =>
        node.children?.some((brandNode) => brandNode.roles.length > 0)
      ),
    [treeState]
  );

  const handleRoleChange = (
    nodeId: number,
    roleName: string,
    modelType: (typeof models)[keyof typeof models]
  ) => {
    setHasBeenChanged(true);
    const updateNodeAndParents = (
      nodes: PermissionLevel[],
      targetNodeId: number,
      targetModelType: (typeof models)[keyof typeof models],
      roleName: string
    ): PermissionLevel[] => {
      const processNode = (node: PermissionLevel): PermissionLevel => {
        if (node.id === targetNodeId && node.model_type === targetModelType) {
          const isSelected = !node.roles.includes(roleName);
          return {
            ...node,
            roles: isSelected
              ? [...new Set([...(node.roles || []), roleName])] // Add role if selected (set makes sure it's unique)
              : (node.roles || []).filter((role) => role !== roleName), // Remove role if deselected
            children: node.children?.length
              ? updateChildren(node.children, isSelected)
              : node.children,
          };
        }

        // Recursively process children if any
        if (
          node.children?.length &&
          node.children.some(
            (child) =>
              child.model_type &&
              allRoles
                ?.find((role) => role.name === roleName)
                ?.available_models?.includes(child.model_type)
          )
        ) {
          const updatedChildren = node.children.map(processNode);
          const allChildrenHaveRole = updatedChildren.every((child) =>
            child.roles.includes(roleName)
          );
          const updatedNode = {
            ...node,
            roles: !allChildrenHaveRole
              ? (node.roles || []).filter((role) => role !== roleName) // Remove role if not all children have it
              : node.roles,
            children: updatedChildren,
          };
          return updatedNode;
        }

        return node;
      };

      return nodes.map(processNode);
    };

    const updateChildren = (
      children: PermissionLevel[],
      isSelected: boolean
    ): PermissionLevel[] =>
      children.map((child) => ({
        ...child,
        roles: isSelected
          ? [...new Set([...child.roles, roleName])] // Add the role to the child
          : child.roles.filter((role) => role !== roleName), // Remove the role from the child
        children: child.children?.length
          ? updateChildren(child.children, isSelected)
          : child.children,
      }));

    setTreeState((prevTree) =>
      updateNodeAndParents(prevTree, nodeId, modelType, roleName)
    );
  };

  return (
    <div tw="overflow-x-auto max-w-full h-auto max-h-[400px] mt-8">
      <div tw="overflow-y-auto h-full">
        {/* Header Row */}
        <div tw="flex gap-4">
          <div tw="shrink-0 p-2 w-[200px]"></div>
          {roles.map((role) => (
            <div
              key={role.id}
              tw="shrink-0 font-medium text-sm text-gray-700 p-2 text-center min-w-[175px]"
            >
              {role.friendly_name}
            </div>
          ))}
        </div>

        {/* Body Rows */}
        <div tw="h-auto">
          {treeState.map((node) => (
            <AccordionNode
              key={node.id}
              node={node}
              roles={roles}
              onChange={handleRoleChange}
              userId={userId}
            />
          ))}
        </div>

        {/* warnings that applying a role to a customer/brand will be applied to all current and future children. */}
        {hasBeenChanged && (
          <div tw="flex flex-col gap-2 mt-4 text-amber-500 text-sm">
            {rolesIncludeCustomer && (
              <div tw="flex gap-2">
                <Warning size={20} weight="regular" />
                <p>
                  Applying a role to a customer means it will be applied to all
                  brands and vacancies.
                </p>
              </div>
            )}
            {rolesIncludeBrand && (
              <div tw="flex gap-2">
                <Warning size={20} weight="regular" />
                <p>
                  Applying a role to a brand means it will be applied to all
                  current and future vacancies.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PermissionGrid;
