import { Title3 } from 'components/typography/Typography';
import React from 'react';
import ActiveVacanciesTable from './ActiveVacanciesTable';
import ApplicationsList from './ApplicationsList';
import EmailsList from './EmailsList';
import 'twin.macro';

const DashboardMetricsSection = () => {
  return (
    <React.Fragment>
      <div tw="grid grid-cols-1 xl:grid-cols-2 gap-8 mt-6">
        <div>
          <Title3>Latest applications</Title3>
          <ApplicationsList />
        </div>
        <div>
          <Title3>Latest messages</Title3>
          <EmailsList />
        </div>
      </div>
      <div tw="mt-6">
        <Title3>Active campaigns</Title3>
        <ActiveVacanciesTable />
      </div>
    </React.Fragment>
  );
};

export default DashboardMetricsSection;
