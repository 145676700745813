import tw, { styled } from 'twin.macro';
import React, { useMemo, useState } from 'react';
import {
  Eye,
  File,
  DownloadSimple,
  Image,
  Trash,
  VideoCamera,
} from '@phosphor-icons/react';

import {
  downloadFile,
  filePathToFilePreview,
  isImageFileExtension,
} from 'utils/fileHelpers';
import { FileTypes } from 'types/fileTypes';
import ToolbarItem from 'components/toolbar-item/ToolbarItem';
import { Modal } from 'components/page/app-page/Modal';
import { CustomerResource } from 'types/apiTypes';
import Img from 'components/image/Image';
import { Body } from 'components/typography/Typography';

export interface CustomerResourceItemProps {
  resource: CustomerResource;
  resourceIdx: number;
  allowDelete?: boolean;
  allowDownload?: boolean;
  onClick?: (resourceIdx?: number) => void;
  onDelete?: (resourceIdx?: number) => void;
}

const TOOLBOX_ICON_SIZE = 20;
export const TOOLBOX_BTN_STYLING = {
  color: '#7A7A7A',
};

const CustomerResourceItem: React.FC<CustomerResourceItemProps> = ({
  resource,
  // resourceIdx,
  allowDelete = true,
  allowDownload = true,
  onDelete,
}) => {
  const [showModal, toggleModal] = useState(false);

  const filePreview = useMemo(
    () => filePathToFilePreview(resource.path),
    [resource.path]
  );

  const isImage = useMemo(
    () => isImageFileExtension(filePreview.fileType),
    [filePreview]
  );

  const isExpandDisabled = useMemo(() => {
    const ext = '.' + resource.path.split('.').reverse()[0].toLowerCase();
    return ext === FileTypes.DOCX || ext === FileTypes.PDF;
  }, [resource.path]);

  const fileTypeIcon = useMemo(() => {
    switch (filePreview.fileType) {
      case FileTypes.MP4:
        return <VideoCamera weight="bold" size={TOOLBOX_ICON_SIZE} />;
      case FileTypes.DOCX:
      case FileTypes.PDF:
        return <File weight="bold" size={TOOLBOX_ICON_SIZE} />;
      case FileTypes.JPEG:
      case FileTypes.JPG:
      case FileTypes.PNG:
      default:
        return <Image weight="bold" size={TOOLBOX_ICON_SIZE} />;
    }
  }, [filePreview.fileType]);

  const formattedDate = useMemo(() => {
    if (resource.created_at)
      return new Date(resource.created_at).toLocaleDateString();
    return undefined;
  }, [resource.created_at]);

  const downloadHandler = () => {
    if (allowDownload) downloadFile(resource.path);
  };

  const deleteHandler = () => {
    if (allowDelete) onDelete?.(resource.id);
  };

  return (
    <>
      <PreviewItemContainer>
        <IconWrapper>
          {resource.thumbnail_path ? (
            <picture tw="h-full w-full max-w-[5rem] lg:max-w-none">
              <Img
                src={resource.thumbnail_path}
                tw="h-10 w-10 object-contain"
                alt={filePreview.fileName}
                errorComponent={
                  <div tw="flex flex-col items-center justify-center h-full">
                    {fileTypeIcon}
                  </div>
                }
              />
            </picture>
          ) : (
            <>{fileTypeIcon}</>
          )}
        </IconWrapper>

        <FileDataWrapper>
          <p tw="flex flex-col items-start w-full text-gray-400 justify-end md:justify-start">
            <span tw="overflow-hidden max-w-full whitespace-nowrap text-sm text-ellipsis inline-block text-gray-700 font-medium">
              {filePreview.fileName}
            </span>
            <span tw="overflow-hidden whitespace-nowrap text-xs text-ellipsis inline-block text-gray-400 font-medium">
              {formattedDate}
            </span>
          </p>
        </FileDataWrapper>

        <ToolboxWrapper>
          <ul tw="flex w-auto place-content-end">
            <ToolbarItem
              hidden={isExpandDisabled}
              disabled={isExpandDisabled}
              Icon={<Eye weight="bold" />}
              iconStyle={TOOLBOX_BTN_STYLING}
              onClick={() => toggleModal(true)}
              tooltip={{
                enabled: true,
                text: 'View',
                placement: 'bottom',
                arrow: true,
              }}
              iconSize={TOOLBOX_ICON_SIZE}
            />
            <ToolbarItem
              hidden={!allowDownload}
              disabled={!allowDownload}
              Icon={<DownloadSimple weight="bold" />}
              iconStyle={TOOLBOX_BTN_STYLING}
              onClick={downloadHandler}
              tooltip={{
                enabled: true,
                text: 'Download',
                placement: 'bottom',
                arrow: true,
              }}
              iconSize={TOOLBOX_ICON_SIZE}
            />
            {allowDelete && (
              <>
                <Separation />
                <ToolbarItem
                  disabled={!allowDelete}
                  Icon={<Trash weight="bold" />}
                  iconStyle={TOOLBOX_BTN_STYLING}
                  onClick={deleteHandler}
                  tooltip={{
                    enabled: true,
                    text: 'Delete',
                    placement: 'bottom',
                    arrow: true,
                  }}
                  iconSize={TOOLBOX_ICON_SIZE}
                />
              </>
            )}
          </ul>
        </ToolboxWrapper>
      </PreviewItemContainer>
      <Modal show={showModal} onClose={() => toggleModal(false)}>
        <ModalHeader>
          <Body>{filePreview.fileName}</Body>
        </ModalHeader>
        <ModalContentContainer>
          {isImage ? (
            <picture tw="w-full h-full object-contain">
              <Img
                src={filePreview.path}
                alt={filePreview.fileName}
                tw="h-full w-full object-contain"
              />
            </picture>
          ) : (
            <video controls>
              <source src={filePreview.path} />
            </video>
          )}
        </ModalContentContainer>
      </Modal>
    </>
  );
};

const PreviewItemContainer = styled.div`
  ${tw`p-2 bg-gray-50 rounded-md my-1 min-h-[80px] place-items-center w-full flex justify-between md:(w-fit h-14 min-h-0)`}
`;

const IconWrapper = styled.div(
  tw`min-w-[4rem] flex items-center h-full justify-center`
);

const FileDataWrapper = styled.div(
  tw`min-w-[24rem] max-w-sm flex items-center justify-self-end md:px-4`
);

const ToolboxWrapper = styled.div(tw`h-auto justify-self-end`);

const Separation = styled.span(
  tw`bg-gray-300 mx-2 relative w-px h-6 rounded-full`
);

const ModalHeader = styled.div(
  tw`flex justify-between py-2.5 border-b-2 border-b-gray-100 mb-1`
);

const ModalContentContainer = styled.div(
  tw`aspect-video md:(max-w-5xl max-h-96)`
);

export default CustomerResourceItem;
