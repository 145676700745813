import { gql } from '@apollo/client';

export const GET_TEMPLATES = gql`
  query getTemplates(
    $pageSize: Int!
    $currentPage: Int!
    $languageId: String
    $type: String
  ) {
    templates(
      pageSize: $pageSize
      currentPage: $currentPage
      languageId: $languageId
      type: $type
    )
      @rest(
        type: "[Template]"
        path: "/v4/templates?sort=-id&page%5Bsize%5D={args.pageSize}&page%5Bnumber%5D={args.currentPage}&filter[language_id]={args.languageId}&type={args.type}"
      ) {
      id
      name
      language_id
      body
      subject
      slug
      customer_id
      meta {
        pagination {
          count
          current_page
          links {
            previous
            next
          }
          per_page
          total
          total_pages
        }
      }
    }
  }
`;

export const GET_TEMPLATE = gql`
  query getTemplateData($id: Int!) {
    template(id: $id) @rest(type: "Template", path: "/v4/templates/{args.id}") {
      name
      subject
      body
      slug
      language_id
      customer_id
    }
  }
`;
