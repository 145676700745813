import React from 'react';
import { Check, Minus } from '@phosphor-icons/react';

interface PartialCheckboxProps {
  checked: boolean | 'partial';
  onChange: () => void;
  disabled?: boolean;
}

const PartialCheckbox = ({
  checked,
  onChange,
  disabled = false,
}: PartialCheckboxProps) => {
  const checkedClasses =
    checked === true
      ? 'bg-primary border-primary'
      : checked === 'partial'
      ? 'bg-primary/50 border-primary/50'
      : 'bg-background border-input';
  const disabledClasses = disabled
    ? 'cursor-not-allowed opacity-30'
    : 'hover:border-primary cursor-pointer';

  return (
    <button
      type="button"
      onClick={onChange}
      disabled={disabled}
      className={`flex h-5 w-5 items-center justify-center rounded-md border-2 transition-colors ${checkedClasses} ${disabledClasses}`}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
      {checked === true && (
        <Check
          width={12}
          height={12}
          weight="bold"
          className="text-primary-foreground h-3 w-3"
        />
      )}
      {checked === 'partial' && (
        <Minus
          width={12}
          height={12}
          weight="bold"
          className="text-primary-foreground h-3 w-3"
        />
      )}
    </button>
  );
};

export default PartialCheckbox;
