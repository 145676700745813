import 'twin.macro';
import React, { FC, useContext } from 'react';

import { CandidateContext, CandidateDetailTab } from 'context/CandidateContext';
import { Routes } from 'types/routeTypes';
import TimeStamp from '../timestamp/TimeStamp';
import IconCard from '../icon-card/IconCard';
import { EmailMetricResponse } from 'types/dashboard/types';
import useNavigationContext from 'hooks/context/nav-context';
import { useQuery } from 'hooks/sympl-query';
import { GET_EMAIL_METRICS } from 'graphql/dashboard/email-metrics/queries';
import { SkeletonList1 } from 'components/skeleton/skeleton-list/SkeletonList';
import { useNavigate } from 'react-router-dom';

const EmailList: FC = () => {
  const navigate = useNavigate();

  const { setActiveDetailTab } = useContext(CandidateContext);
  const { setActiveVacancy, activeCustomer } = useNavigationContext();

  const { loading, data } = useQuery<EmailMetricResponse>(GET_EMAIL_METRICS, {
    fetchPolicy: 'no-cache',
    skip: !activeCustomer,
  });

  const mails = data?.campaignMetrics?.emails ?? [];

  const handleClick = (procedureId: number, vacancyId: number): void => {
    setActiveDetailTab(CandidateDetailTab.COMMUNICATION);
    setActiveVacancy({ vacancyId: vacancyId });
    navigate(Routes.PROCEDURES + '/' + procedureId);
  };

  if (loading) return <SkeletonList1 rows={3} />;

  if (mails.length <= 0)
    return (
      <p tw="text-center text-gray-500 bg-white shadow rounded-md p-4">
        No emails yet this week.
      </p>
    );

  return (
    <ul tw="w-full flex flex-col justify-start space-y-2">
      {mails.slice(0, 5).map((mail, index) => (
        <li key={index}>
          <IconCard
            icon="mail"
            label={mail.candidate_name}
            sublabel={mail.vac_name}
            suffix={<TimeStamp time={mail.inbound_date} />}
            onClick={() => handleClick(mail.proc_id, mail.vac_id)}
          />
        </li>
      ))}
    </ul>
  );
};

export default EmailList;
