import { Modal } from 'components/page/app-page/Modal';
import React, { useState } from 'react';
import { Brand } from 'types/customer/types';
import { Title2 } from 'components/typography/Typography';
import 'twin.macro';
import Button from 'components/button/Button';
import Input from 'components/form/input/Input';

interface IBrandModal {
  modalType: ModalType;
  showModal: boolean;
  loading?: boolean;
  closeModal: () => void;
  brandItem?: Brand;
  onClick: (props: IModalProps) => void;
}
export interface IModalProps {
  id?: number;
  brandName: string;
}
export enum ModalType {
  ADD,
  DELETE,
}

export const BrandModal: React.FC<IBrandModal> = ({
  modalType,
  showModal,
  loading,
  closeModal,
  brandItem,
  onClick,
}: IBrandModal) => {
  const [input, setInput] = useState(brandItem?.name ?? '');

  const buttonText = {
    [ModalType.ADD]: 'Add brand',
    [ModalType.DELETE]: 'I understand and agree',
  };

  const modalTitle = {
    [ModalType.ADD]: 'Add brand',
    [ModalType.DELETE]: `Delete brand ${brandItem?.name}`,
  };

  const ModalContent = () => {
    switch (modalType) {
      case ModalType.ADD:
        return (
          <div tw="flex flex-row gap-4 items-center">
            <span tw="text-gray-500 w-1/2 ">Brand name: </span>
            <Input
              name="brandName"
              id="brandName"
              defaultValue={brandItem?.name}
              onChange={(val) => setInput(val.target.value)}
            />
          </div>
        );

      case ModalType.DELETE:
        return (
          <div tw="max-w-[500px]">
            <p>
              Are you sure you want to delete brand <b>{brandItem?.name}</b>?
            </p>
            <p tw="mt-4">
              This action is <b>irreversible</b> and will have the following
              consequences:
            </p>
            <ul tw="mt-4">
              <li>
                All <b>campaigns</b> linked to this brand will be stopped and
                deleted.
              </li>
              <li>
                All <b>candidate procedures</b> linked to this brand will be
                deleted.
              </li>
              <li>
                The <b>career page</b> for this brand will be unhosted and
                deleted.
              </li>
            </ul>
          </div>
        );
    }
  };
  return (
    <Modal show={showModal} onClose={() => closeModal()}>
      <Title2>{modalTitle[modalType]}</Title2>
      <hr tw="mb-3" />
      <div tw="flex flex-col py-2 gap-8">
        {ModalContent()}
        <div tw="flex flex-row gap-4 justify-end">
          <Button
            tw="sm:(block hidden) w-1/3 flex-auto"
            variant="transparent"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button
            tw="sm:(block hidden) flex-auto"
            variant={modalType === ModalType.DELETE ? 'danger' : 'primary'}
            loading={loading}
            onClick={() =>
              onClick(
                modalType !== ModalType.ADD
                  ? { id: brandItem?.id!, brandName: input }
                  : { brandName: input }
              )
            }
          >
            {buttonText[modalType]}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
