import React from 'react';
import tw, { styled } from 'twin.macro';

interface MosaicLoaderProps {
  color?: string | string[];
  size?: number;
  speed?: number;
  text?: string;
  textColor?: string;
}

const Container = styled.div`
  ${tw`flex flex-col items-center justify-center`}
`;

const Grid = styled.div<{ size: number }>`
  ${tw`grid grid-cols-3 gap-1`}
  width: ${(props) => props.size * 3 + 8}px;
  height: ${(props) => props.size * 3 + 8}px;
`;

const Cube = styled.div<{
  size: number;
  color: string;
  delay: number;
  speed: number;
}>`
  ${tw`rounded`}
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) => props.color};
  animation: pulse ${(props) => props.speed}s infinite ease-in-out
    ${(props) => props.delay}s;

  @keyframes pulse {
    0%,
    100% {
      opacity: 0.3;
      transform: scale(0.8);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const Text = styled.p<{ color: string }>`
  ${tw`mt-2 text-sm font-medium`}
  color: ${(props) => props.color};
`;

const MosaicLoader: React.FC<MosaicLoaderProps> = ({
  color = '#fd4c5a',
  size = 40,
  speed = 2,
  text,
  textColor = '#333',
}) => {
  const cubes = Array.from({ length: 9 }, (_, i) => i + 1);
  const colors = Array.isArray(color) ? color : [color, color, color, color];

  return (
    <Container role="status" aria-label="Loading">
      <Grid size={size}>
        {cubes.map((cubeIndex) => (
          <Cube
            key={cubeIndex}
            size={size}
            color={colors[(cubeIndex - 1) % colors.length]}
            delay={(cubeIndex - 1) * (speed / 18)}
            speed={speed}
          />
        ))}
      </Grid>
      {text && <Text color={textColor}>{text}</Text>}
    </Container>
  );
};

export default MosaicLoader;
