import React, { useMemo, useEffect } from 'react';
import { useMutation } from 'hooks/sympl-mutation';
import { Controller, useForm } from 'react-hook-form';

import ApiDropdown from 'components/dropdown/api-dropdown/ApiDropdown';
import Input from 'components/form/input/Input';
import SettingsInput from 'components/form/input/SettingsInput';
import AddressInput from 'components/form/AddressInput';
import VatInput from 'components/form/VatInput';
import SaveButton from 'components/save-button/SaveButton';
import { CustomerAddress } from 'types/geolocationTypes';
import { MAIL_REGEX, VAT_NUMBER_REGEX } from 'utils/regexHelpers';
import Container from 'components/container/Container';
import ContainerHeader from 'components/container/container-header/ContainerHeader';
import ContainerAction from 'components/container/container-action/ContainerAction';
import { UPDATE_CUSTOMER } from 'graphql/customers/mutations';
import { Customer } from 'types/customer/types';
import { Footnote, Subhead, Title2 } from 'components/typography/Typography';
import { addressValidationHandler } from 'utils/addressHelpers';
import useUnsavedFields from 'hooks/unsaved';
import useNavigationContext from 'hooks/context/nav-context';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import 'twin.macro';
import ToggleButton from 'components/toggle-button/ToggleButton';
import InformationToolTip from 'components/tooltip/InformationToolTip';
import PermissionWrapper from 'components/permissions/permissionWrapper/PermissionWrapper';
import { PERMISSIONS } from 'context/PermissionContext';

type SettingsInputs = {
  name: string;
  invoicing_name: string;
  invoicing_email: string;
  invoice_address: CustomerAddress;
  vat: string | null;
  legal_form: string;

  // the api is returning the number as string. a bit confused on this part , so I added number , string and null
  default_po_number: string | null;
  gdpr_deletion_period: number;

  // 2fa
  two_factor_enabled: boolean;
};

type FieldValue = string | boolean | CustomerAddress;

export type UpdateCustomerFormKey = keyof Omit<Customer, 'subscription'>;

interface UpdateCustomerPayload {
  input: Customer;
}

const GeneralSettings = ({
  saveLabel,
  onSave,
  minimal = false,
}: {
  saveLabel?: string;
  onSave?: () => void;
  minimal?: boolean;
}) => {
  const { customer, activeCustomer, refetchCustomer } = useNavigationContext();
  const { addToast } = useToastNotifications();

  const formMethods = useForm<SettingsInputs>({
    defaultValues: {
      name: '',
      invoicing_name: '',
      invoicing_email: '',
      invoice_address: undefined,
      vat: '',
      legal_form: '',
      default_po_number: '',
      gdpr_deletion_period: undefined,
      two_factor_enabled: customer?.two_factor_enabled || false,
    },
  });

  const {
    control,
    errors: formErrors,
    handleSubmit,
    reset,
    setValue,
  } = formMethods;

  const [shouldSaveChanges, resetUnsavedFlag] = useUnsavedFields(formMethods);

  useEffect(() => {
    if (customer) reset(customer);
  }, [customer, reset]);

  const [
    updateCustomer,
    { loading: updateCustomerLoading, error: updateCustomerError },
  ] = useMutation<undefined, UpdateCustomerPayload>(UPDATE_CUSTOMER);

  const settingSaveHandler = async (data: Customer) => {
    // Validate save request
    if (!activeCustomer) return;

    // Update the customer props
    const updatedCustomer: any = {
      ...customer,
      ...data,
      office_address: data.invoice_address, // TODO: remove this temporary fix
    };

    // Update the customer settings
    await updateCustomer({
      variables: {
        input: updatedCustomer,
      },
    });

    addToast({
      type: ToastTypes.SUCCESS,
      description: 'Settings saved successfully',
    });

    // workaround for visual bug
    if (updatedCustomer.two_factor_enabled !== customer?.two_factor_enabled) {
      setValue('two_factor_enabled', updatedCustomer.two_factor_enabled);
    }

    resetUnsavedFlag();
    refetchCustomer();

    onSave?.();
  };

  const hasFormErrors = useMemo(
    () => Object.keys(formErrors).length > 0,
    [formErrors]
  );

  const updateField = (
    field: UpdateCustomerFormKey,
    value: FieldValue,
    onChange: (value: FieldValue) => void
  ) => {
    setValue(field, value);
    onChange(value);
  };

  const vatBlurHandler = (
    { target: { value } }: React.FocusEvent<HTMLInputElement>,
    onChange: (value: FieldValue) => void
  ) => {
    updateField('vat', value, onChange);
  };

  return (
    <div>
      <Container>
        <form onSubmit={handleSubmit(settingSaveHandler)}>
          {!minimal && (
            <>
              <ContainerHeader>
                <Title2 mb={0}>Company Details</Title2>
                <Subhead isLight>
                  Practical information we need from you.
                </Subhead>
              </ContainerHeader>
              <hr tw="mt-4" />
            </>
          )}
          <SettingsInput
            label="Name"
            errorMessage="This field is required"
            hasError={formErrors['name'] !== undefined}
          >
            <Controller
              id="name"
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <div tw="flex flex-col w-full">
                  <div tw="grow">
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      value={value}
                      ariaInvalid={formErrors['name'] !== undefined}
                      onChange={(e) =>
                        updateField('name', e.target.value, onChange)
                      }
                    />
                  </div>
                </div>
              )}
            />
          </SettingsInput>
          <hr />
          <SettingsInput
            label="Invoicing Name"
            errorMessage="This field is required"
            hasError={formErrors['invoicing_name'] !== undefined}
          >
            <Controller
              id="invoicing_name"
              name="invoicing_name"
              control={control}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <div tw="flex flex-col w-full">
                  <div tw="grow">
                    <Input
                      id="invoicing_name"
                      name="invoicing_name"
                      type="text"
                      value={value}
                      ariaInvalid={formErrors['invoicing_name'] !== undefined}
                      onChange={(e) =>
                        updateField('invoicing_name', e.target.value, onChange)
                      }
                    />
                  </div>
                </div>
              )}
            />
          </SettingsInput>
          <hr />
          <SettingsInput
            label="Invoicing address"
            errorMessage="Please provide a valid address"
            hasError={formErrors['invoice_address'] !== undefined}
          >
            <Controller
              id="invoice_address"
              name="invoice_address"
              control={control}
              rules={{
                validate: (address) => addressValidationHandler(true, address),
              }}
              render={({ value, onChange }) => (
                <AddressInput
                  value={value}
                  onChange={(address) =>
                    updateField('invoice_address', address, onChange)
                  }
                />
              )}
            />
          </SettingsInput>
          <hr />
          <SettingsInput
            label="Invoicing e-mail"
            errorMessage="Please provide a valid e-mail"
            hasError={formErrors['invoicing_email'] !== undefined}
          >
            <Controller
              id="invoicing_email"
              name="invoicing_email"
              control={control}
              rules={{
                required: true,
                pattern: { value: MAIL_REGEX, message: 'test' },
              }}
              render={({ value, onChange }) => (
                <div tw="flex flex-col w-full">
                  <div tw="grow">
                    <Input
                      id="invoicing_email"
                      name="invoicing_email"
                      type="email"
                      value={value}
                      ariaInvalid={formErrors['invoicing_email'] !== undefined}
                      onChange={(e) =>
                        updateField('invoicing_email', e.target.value, onChange)
                      }
                    />
                  </div>
                </div>
              )}
            />
          </SettingsInput>
          <hr />
          <SettingsInput
            label="VAT"
            errorMessage={
              formErrors['vat']?.message || 'Please provide a valid VAT'
            }
            hasError={formErrors['vat'] !== undefined}
          >
            <Controller
              id="vat"
              name="vat"
              control={control}
              rules={{
                validate: (value) => {
                  return (
                    value === '' ||
                    value === undefined ||
                    value === null ||
                    VAT_NUMBER_REGEX.test(value) ||
                    'Please fill in a valid VAT number'
                  );
                },
              }}
              render={({ value, onChange }) => (
                <div tw="flex flex-col w-full">
                  <div tw="grow">
                    <VatInput
                      id="vat"
                      name="vat"
                      type="text"
                      // since value can be returned as null from the API. the workaround is to show an empty string instead
                      value={value || ''}
                      ariaInvalid={formErrors['vat'] !== undefined}
                      onChange={(e) =>
                        updateField('vat', e.target.value, onChange)
                      }
                      onBlur={(e) => vatBlurHandler(e, onChange)}
                    />
                  </div>
                </div>
              )}
            />
          </SettingsInput>
          <hr />
          <SettingsInput
            label="Legal form"
            errorMessage="This field is required"
            hasError={formErrors['legal_form'] !== undefined}
          >
            <Controller
              id="legal_form"
              name="legal_form"
              control={control}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <div tw="grow">
                  <ApiDropdown
                    type="customer_legal_form"
                    mode="key"
                    onChange={(value) => {
                      if (!value) return;
                      const key =
                        typeof value === 'string'
                          ? value
                          : value.key.toString();
                      updateField('legal_form', key, onChange);
                    }}
                    value={value}
                  />
                </div>
              )}
            />
          </SettingsInput>
          <hr />
          <SettingsInput
            label="PO number"
            errorMessage="Please enter a number"
            hasError={formErrors['default_po_number'] !== undefined}
          >
            <Controller
              id="default_po_number"
              name="default_po_number"
              control={control}
              render={({ value, onChange }) => (
                <div tw="flex flex-col w-full">
                  <div tw="grow">
                    <Input
                      id="default_po_number"
                      name="default_po_number"
                      type="text"
                      // since value can be returned as null from the API. the workaround is to show an empty string instead
                      value={value || ''}
                      ariaInvalid={
                        formErrors['default_po_number'] !== undefined
                      }
                      onChange={(e) =>
                        updateField(
                          'default_po_number',
                          e.target.value,
                          onChange
                        )
                      }
                    />
                  </div>
                </div>
              )}
            />
          </SettingsInput>
          <hr />
          <SettingsInput
            label="Two-Factor Authentication"
            tooltip={
              <InformationToolTip
                name="two_factor_enabled"
                text="When enabled, users will be required to enter a code sent to their email to log in. This check is done once a month. This setting will be enforced for all users in your company."
                icon="information"
              />
            }
            errorMessage="Something went wrong. Please try again."
            hasError={formErrors['two_factor_enabled'] !== undefined}
          >
            <Controller
              id="two_factor_enabled"
              name="two_factor_enabled"
              control={control}
              render={({ value, onChange }) => (
                <div tw="flex flex-row w-fit gap-4">
                  <p tw="text-gray-700 text-sm">Off</p>
                  <ToggleButton
                    id="two_factor_enabled"
                    name="two_factor_enabled"
                    value={value}
                    onChange={(e) => {
                      updateField(
                        'two_factor_enabled',
                        e.target.checked,
                        onChange
                      );
                    }}
                  />
                  <p tw="text-gray-700 text-sm">On</p>
                </div>
              )}
            />
          </SettingsInput>

          <SettingsInput
            label="Candidate data deletion period (months)"
            errorMessage="Please enter a valid number between 12 and 96."
            hasError={formErrors['gdpr_deletion_period'] !== undefined}
            tooltip={
              <InformationToolTip
                tw="-ml-4"
                icon="information"
                name="GDPR deletion period"
                text="The sympl software automatically removes data related to a specific candidate after a specified amount months. This feature helps to respect candidates' privacy. Months are counted starting from the date of the most recent application by a specific candidates."
              />
            }
          >
            <Controller
              id="gdpr_deletion_period"
              name="gdpr_deletion_period"
              control={control}
              rules={{
                min: 12,
                max: 96,
                valueAsNumber: true,
                validate: (value) => {
                  return (
                    (value && value >= 12 && value <= 96) ||
                    'Please enter a number between 12 and 96'
                  );
                },
              }}
              render={({ value, onChange }) => (
                <div tw="flex flex-col w-full">
                  <div tw="grow">
                    <Input
                      id="gdpr_deletion_period"
                      name="gdpr_deletion_period"
                      type="number"
                      min={12}
                      max={96}
                      value={value}
                      ariaInvalid={
                        formErrors['gdpr_deletion_period'] !== undefined
                      }
                      onChange={(e) =>
                        updateField(
                          'gdpr_deletion_period',
                          e.target.value,
                          onChange
                        )
                      }
                    />
                    <Footnote mt={1}>Sympl advises 24 months.</Footnote>
                  </div>
                </div>
              )}
            />
          </SettingsInput>

          <ContainerAction>
            <PermissionWrapper permission={PERMISSIONS.MANAGE_FINANCES}>
              <SaveButton
                type="submit"
                loading={updateCustomerLoading}
                error={hasFormErrors || updateCustomerError}
                shouldSave={!minimal && shouldSaveChanges && !hasFormErrors}
                labelSave={saveLabel}
              />
            </PermissionWrapper>
          </ContainerAction>
        </form>
      </Container>
    </div>
  );
};

export default GeneralSettings;
