import React, { useState } from 'react';
import { Plus } from '@phosphor-icons/react';
import CreateCampaignForm from './CreateCampaignForm';
import Button from 'components/button/Button';
import 'twin.macro';
import CreateCampaignModal from './CreateCampaignModal';

const CreateCampaignButton: React.FC<{
  onCampaignCreate?: (vacancyId: number) => void;
  onButtonClick?: () => void;
  onCampaignCancel?: () => void;
  buttonVariant?: 'inline' | 'button';
  formVariant?: 'modal' | 'inline';
  buttonText?: string;
}> = ({
  onCampaignCreate,
  onButtonClick,
  onCampaignCancel,
  buttonVariant = 'inline',
  formVariant = 'modal',
  buttonText,
}) => {
  const [showForm, setShowForm] = useState(false);

  const toggleModal = () => {
    onButtonClick?.();
    setShowForm(!showForm);
  };

  return (
    <>
      {buttonVariant === 'inline' && (
        <span
          id="create-campaign-button"
          onClick={toggleModal}
          tw="ml-auto mr-2 flex cursor-pointer flex-row items-center text-xs text-gray-600"
        >
          <Plus weight="bold" />
          New
        </span>
      )}
      {buttonVariant === 'button' &&
        !(formVariant === 'inline' && showForm) && (
          <Button
            id="create-campaign-button"
            onClick={toggleModal}
            autoFocus={true}
            stretch
          >
            {buttonText ?? 'Start your campaign'}
          </Button>
        )}
      {formVariant === 'modal' && (
        <CreateCampaignModal
          show={showForm}
          onClose={() => setShowForm(false)}
          setShowForm={setShowForm}
          onCreate={(data) => {
            setShowForm(false);
            onCampaignCreate?.(data);
          }}
          onCancel={() => {
            setShowForm(false);
            onCampaignCancel?.();
          }}
          buttonText={buttonText}
        />
      )}
      {formVariant === 'inline' && (
        <div hidden={!showForm}>
          <CreateCampaignForm
            onCreate={onCampaignCreate}
            onCancel={onCampaignCancel}
            buttonText={buttonText}
          />
        </div>
      )}
    </>
  );
};

export default CreateCampaignButton;
