import React, { useMemo, useState } from 'react';
import tw from 'twin.macro';
import { Brand } from 'types/customer/types';
import ColorPicker from 'components/form/ColorPicker';
import Button from 'components/button/Button';
import useModalContext from 'hooks/context/modals-context';
import ResourceUpload from 'components/upload/ResourceUpload';
import { CustomerResource } from 'types/apiTypes';
import ApiDropdown from 'components/dropdown/api-dropdown/ApiDropdown';
import { useMutation } from 'hooks/sympl-mutation';
import { UPDATE_BRAND_DEFAULTS } from 'graphql/brands/mutations';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import { Image, X, TrashSimple } from '@phosphor-icons/react';
import { Title3 } from 'components/typography/Typography';
import MultiLocation from 'components/multi-location/MultiLocation';
import useApiList from 'hooks/useApiList';
import { TargetingLocation } from 'types/geolocationTypes';
import { DropdownItem } from 'components/dropdown/Dropdown';
import SaveButton from 'components/save-button/SaveButton';
import Input from 'components/form/input/Input';
import Label from 'components/form/Label';
import FacebookPageModal from 'components/modals/FacebookPageModal';
import { FileTypes } from 'types/fileTypes';
import useNavigationContext from 'hooks/context/nav-context';
import { ADD_CUSTOM_DOMAIN } from 'graphql/brands/mutations';
import { parseDomain, ParseResultType } from 'parse-domain';
import InformationToolTip from 'components/tooltip/InformationToolTip';
import ToolTip from 'components/tooltip/ToolTip';
import DnsStatus, { Validation } from 'views/settings/brands/DnsStatus';
import { fireEvent } from 'utils/eventHelper';
import { showIntercom } from 'utils/intercomHelper';

export interface BrandEditFormProps {
  brand: Brand;
  handleDelete: () => void;
}

interface UpdateBrandDefaultsPayload {
  brandId: number;
  input: Omit<Omit<BrandEditFormState, 'default_logo'>, 'default_creatives'> & {
    default_logo_id: number | null;
    default_creatives: number[];
  };
}

interface BrandEditFormState {
  name: string;
  default_primary_color?: string;
  default_secondary_color?: string;
  default_tertiary_color?: string;
  default_recruiter_id: number | null;
  default_logo?: CustomerResource | null;
  default_language_id: number;
  default_creatives?: CustomerResource[];
  default_locations?: TargetingLocation[];
  default_display_link?: string;
  default_banner_title?: string;
  default_facebook_page_id?: number;
  custom_domain?: string;
  custom_domain_validations?: Validation[];
}

interface ConnectDomainPayload {
  brandId: number;
  input: { domain: string; sub_domain: string };
}

export const BrandEditForm: React.FC<BrandEditFormProps> = ({
  brand,
  handleDelete,
}) => {
  const brandFormData = {
    name: brand.name ?? '',
    default_primary_color: brand.default_primary_color ?? '#000000',
    default_secondary_color: brand.default_secondary_color ?? '#000000',
    default_tertiary_color: brand.default_tertiary_color ?? '#000000',
    default_recruiter_id: brand.default_recruiter?.id ?? null,
    default_logo: brand.default_logo ? brand.default_logo : null,
    default_language_id: brand.default_language_id ?? 141,
    default_creatives: brand.default_creatives ?? [],
    default_locations: brand.default_locations ?? [],
    default_display_link: brand.default_display_link ?? undefined,
    default_banner_title: brand.default_banner_title ?? undefined,
    default_facebook_page_id: brand.default_facebook_page_id ?? undefined,
    custom_domain: brand.custom_domain ?? undefined,
  };

  // TODO: move to react-hook-forms + use unsaved.ts hook
  const [formData, _setFormData] = useState<BrandEditFormState>(brandFormData);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { pushModal, removeModal } = useModalContext();
  const { addToast } = useToastNotifications();
  const { currentVacancy, refetchBrands, isAdmin, refetchCurrentVacancy } =
    useNavigationContext();
  const [domainError, setDomainError] = useState(false);
  const [domainValidations, setDomainValidations] = useState<Validation[]>(
    brand.custom_domain_validations ?? []
  );

  const { data: languages } = useApiList('languages');

  const brandHasCustomDomain = useMemo(() => {
    if (brand.custom_domain) return true;
    if (domainValidations && domainValidations.length) return true;
    return false;
  }, [brand, domainValidations]);

  const domainIsVerified = useMemo(
    () => !!(brand.custom_domain && domainValidations.length === 0),
    [brand]
  );

  const setFormData = (data: BrandEditFormState) => {
    _setFormData(data);
    setHasUnsavedChanges(true);
  };

  const [connectDomain, { loading: domainLoading }] = useMutation<
    {
      addCustomDomain: {
        status: string;
        validations: Validation[];
      };
    },
    ConnectDomainPayload
  >(ADD_CUSTOM_DOMAIN);

  const [updateBrandDefaults, { loading }] = useMutation<
    {
      updateBrandDefaults: {
        sessionId?: string;
      };
    },
    UpdateBrandDefaultsPayload
  >(UPDATE_BRAND_DEFAULTS);

  const getLanguage = (languageId: number) => {
    const selectedLanguage = languages.find(
      ({ key }) => key === languageId
    )?.label;
    return selectedLanguage;
  };

  const handleLanguageChange = (languageId: number) => {
    setFormData({ ...formData, default_language_id: languageId });
  };

  const handleLeadRecruiterChange = (userId: number) => {
    setFormData({ ...formData, default_recruiter_id: userId });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // log the form data and prevent the default form submit
    e.preventDefault();
    updateBrandDefaults({
      variables: {
        brandId: brand.id ?? 0,
        input: {
          ...formData,
          default_logo_id: formData.default_logo?.id ?? null,
          default_creatives: (formData.default_creatives ?? []).map(
            ({ id }) => id
          ),
          default_locations: formData?.default_locations?.map((location) => {
            return { ...location, lon: location.lng };
          }),
        },
      },
    })
      .then(() => {
        addToast({
          title: 'Success',
          description: `You successfully updated your preferences for ${brand.name}.`,
          type: ToastTypes.SUCCESS,
        });

        setHasUnsavedChanges(false);
        refetchBrands();
        if (currentVacancy?.brand_id === brand.id) refetchCurrentVacancy();
      })
      .catch(() =>
        addToast({
          title: 'Error',
          description: 'Something went wrong, please try again.',
          type: ToastTypes.ERROR,
        })
      );
  };

  const hasSubdomain = (domain: string) => {
    const parsedDomain = parseDomain(domain);
    if (parsedDomain.type === ParseResultType.Listed) {
      const { subDomains } = parsedDomain;
      return subDomains;
    }
    return [];
  };

  const handleNewDomain = () => {
    const domainResult = hasSubdomain(formData.custom_domain ?? '');
    if (!domainResult.length) {
      setDomainError(true);
      setTimeout(() => {
        setDomainError(false);
      }, 3000);
      return;
    }

    connectDomain({
      variables: {
        brandId: brand.id,
        input: {
          domain: formData.custom_domain ?? '',
          sub_domain: domainResult.pop() ?? '',
        },
      },
    }).then((result) => {
      fireEvent('request_custom_subdomain', {
        brand: brand.id,
        domain: formData.custom_domain,
      });
      const data = result?.data?.addCustomDomain;
      setDomainValidations(data?.validations as Validation[]);
    });
  };

  return (
    <form tw="h-full bg-gray-50 p-8 relative rounded-b" onSubmit={handleSubmit}>
      <div tw="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full box-border">
        {/* Non-imagery */}
        <div tw="flex flex-col gap-8 bg-white shadow-md p-8 rounded-lg box-border">
          {/* brand name */}
          <div tw="flex flex-col gap-2">
            <div tw="flex flex-col">
              <Title3>Brand name</Title3>
            </div>
            <div tw="flex gap-4 flex-wrap gap-y-6">
              <div tw="flex flex-col justify-between w-full">
                <Input
                  id="brandName"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  tw="w-full"
                />
              </div>
            </div>
          </div>

          {/* Other data */}
          <div tw="flex flex-col gap-2">
            <div tw="flex flex-col">
              <Title3>Defaults</Title3>
            </div>

            <div tw="flex gap-4 flex-wrap gap-y-6">
              {/* lead recruiter */}
              <div tw="flex flex-col justify-between h-20">
                <Label htmlFor="leadRecruiter">Lead recruiter</Label>
                <ApiDropdown
                  type="recruiters"
                  mode="key"
                  placeholder={
                    (brand.default_recruiter &&
                      `${brand.default_recruiter?.firstname} ${brand.default_recruiter?.lastname}`) ??
                    'Select a lead recruiter'
                  }
                  onChange={(val) =>
                    handleLeadRecruiterChange(
                      (val as DropdownItem).key as number
                    )
                  }
                />
              </div>

              {/* Language (id) */}
              <div tw="flex flex-col justify-between h-20">
                <Label htmlFor="language">Campaign Language</Label>
                <ApiDropdown
                  mode="key"
                  value={formData.default_language_id}
                  type="languages"
                  onChange={(val) =>
                    handleLanguageChange((val as DropdownItem).key as number)
                  }
                />
              </div>
            </div>
          </div>

          {/* Locations */}
          <div tw="flex flex-col gap-2">
            <Title3>Default targeting locations</Title3>
            <div
              className="location__container"
              tw="flex mb-2 gap-2 min-h-[2.5rem] flex-wrap"
            >
              {formData.default_locations?.map((location, index) => (
                <div
                  key={index}
                  className="group"
                  tw="flex gap-2 w-fit items-center bg-gray-100 rounded-md p-2 relative"
                >
                  <span tw="text-sm">{location.name}</span>
                  <span tw="text-sm text-gray-500">{location.radius} km</span>

                  <div
                    tw="absolute top-0 left-0 grid w-full h-full rounded-md bg-red-400 place-items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover:cursor-pointer"
                    onClick={() => {
                      setFormData({
                        ...formData,
                        default_locations: formData.default_locations?.filter(
                          (l) => l.id !== location.id
                        ),
                      });
                    }}
                  >
                    <TrashSimple
                      weight="bold"
                      tw="text-white cursor-pointer rounded-full"
                      size={20}
                    />
                  </div>
                </div>
              ))}

              <Button
                customStyle={tw`w-fit`}
                onClick={() => {
                  pushModal({
                    show: true,
                    twclass: tw`box-content`,
                    children: (
                      <div tw="w-[70vw] h-fit">
                        <MultiLocation
                          language={
                            getLanguage(formData.default_language_id) ??
                            'English'
                          }
                          defaultValue={formData.default_locations}
                          onChange={(locations) =>
                            setFormData({
                              ...formData,
                              default_locations: locations,
                            })
                          }
                        />

                        <div tw="flex justify-end gap-2">
                          <Button
                            variant="default"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                default_locations: formData.default_locations,
                              });
                              removeModal('default-locations');
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              removeModal('default-locations');
                            }}
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    ),
                    key: 'default-locations',
                  });
                }}
              >
                Add locations
              </Button>
            </div>
          </div>

          {/* Social Media defaults */}
          <div tw="flex flex-col gap-2">
            <Title3>Default social media settings</Title3>
            <div>
              <Label htmlFor="displayLink">Display link</Label>
              <Input
                id="displayLink"
                defaultValue={formData.default_display_link}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    default_display_link: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <Label htmlFor="bannerTitle">Banner title</Label>
              <Input
                id="bannerTitle"
                defaultValue={formData.default_banner_title}
                placeholder="e.g. Wanted"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    default_banner_title: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <Label htmlFor="displayLink">Facebook page</Label>
              {/* Select the prefered facebook page */}
              <div tw="flex gap-4 items-center">
                <ApiDropdown
                  type="facebook_pages"
                  mode="key"
                  placeholder="Use the sympl page"
                  value={formData.default_facebook_page_id}
                  onChange={(val) =>
                    setFormData({
                      ...formData,
                      default_facebook_page_id: (val as DropdownItem)
                        .key as number,
                    })
                  }
                  onClear={() => {
                    setFormData({
                      ...formData,
                      default_facebook_page_id: undefined,
                    });
                  }}
                />

                {/* Add Facebook  pages via modal */}
                <FacebookPageModal
                  brandId={brand.id}
                  onComplete={refetchBrands} // TODO: check why this does not refresh the dropdown list
                />
              </div>
            </div>

            {/* Custom domain */}
            <div>
              <div tw="flex gap-2 items-center">
                <Label htmlFor="custom-domain" fullWidth={false}>
                  Your custom subdomain
                </Label>
                <InformationToolTip
                  name="custom-domain"
                  text="Linking a custom domain to your brand will allow you to use your own domain for your job listings."
                  icon="information"
                />
              </div>

              {isAdmin ? (
                <div tw="flex gap-4 items-center">
                  <ToolTip
                    text="A custom subdomain should be like example.sympl.be"
                    placement="top"
                    visible={domainError}
                    arrow
                  >
                    <Input
                      id="custom-domain"
                      defaultValue={formData.custom_domain}
                      placeholder="e.g. vacancies.example.com"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          custom_domain: e.target.value,
                        })
                      }
                      disabled={!!brandHasCustomDomain}
                    />
                  </ToolTip>

                  <Button
                    onClick={handleNewDomain}
                    loading={domainLoading}
                    disabled={!!brandHasCustomDomain}
                  >
                    Request connection
                  </Button>
                </div>
              ) : brandHasCustomDomain ? (
                <div>
                  <Input
                    id="custom-domain"
                    defaultValue={formData.custom_domain}
                    placeholder="subdomain.domain.com"
                    disabled
                  />
                </div>
              ) : (
                <div tw="flex gap-4 items-center">
                  <Button
                    onClick={() => {
                      fireEvent('custom_domain_interested');
                      showIntercom();
                    }}
                  >
                    I'm interested 🙋‍♂️
                  </Button>
                </div>
              )}

              {!!brandHasCustomDomain && (
                <DnsStatus
                  status={domainIsVerified ? 'verified' : 'pending'}
                  validations={domainValidations}
                  label="Please add the followig records to your DNS settings:"
                />
              )}
            </div>
          </div>
        </div>

        {/* Imagery & name */}
        <div
          className="images_logos"
          tw="flex flex-col gap-8 bg-white shadow-md p-8 rounded-lg"
        >
          {/* Colors */}
          <div className="colors" tw="flex flex-col gap-2">
            <div tw="flex flex-col">
              <Title3>Default colors</Title3>
            </div>
            <div tw="flex gap-8 gap-y-6 flex-wrap">
              {/* primary color */}
              <div tw="flex flex-col justify-between">
                <Label htmlFor="primaryColor">Primary color</Label>
                <ColorPicker
                  id="secondaryColor"
                  value={formData.default_primary_color}
                  ariaInvalid={false}
                  format="hex"
                  onChange={(color: string) =>
                    setFormData({ ...formData, default_primary_color: color })
                  }
                  align="left"
                  extraTop={true}
                />
              </div>
              {/* secondary color */}
              <div tw="flex flex-col justify-between">
                <Label htmlFor="secondaryColor">Secondary color</Label>
                <ColorPicker
                  id="secondaryColor"
                  value={formData.default_secondary_color}
                  ariaInvalid={false}
                  format="hex"
                  onChange={(color: string) =>
                    setFormData({ ...formData, default_secondary_color: color })
                  }
                  align="left"
                  extraTop={true}
                />
              </div>
              {/* tertiary color */}
              <div tw="flex flex-col justify-between">
                <Label htmlFor="tertiaryColor">Tertiary color</Label>
                <ColorPicker
                  id="tertiaryColor"
                  value={formData.default_tertiary_color}
                  ariaInvalid={false}
                  format="hex"
                  onChange={(color: string) =>
                    setFormData({ ...formData, default_tertiary_color: color })
                  }
                  align="left"
                  extraTop={true}
                />
              </div>
            </div>
          </div>

          {/* logo */}
          <div tw="flex flex-col justify-between">
            <Title3>Default logo</Title3>
            <div>
              {formData.default_logo ? (
                <div
                  className="group"
                  tw="relative w-fit min-h-[150px] flex flex-col justify-center shadow-md rounded-md"
                >
                  <img
                    src={formData.default_logo?.thumbnail_path}
                    alt="logo"
                    width={150}
                    height={150}
                    tw="rounded-md"
                  />
                  <X
                    tw="absolute top-2 right-2 text-2xl text-gray-500 cursor-pointer rounded-full bg-white hidden group-hover:block"
                    size={24}
                    onClick={() =>
                      setFormData({ ...formData, default_logo: null })
                    }
                  />
                </div>
              ) : (
                <div
                  tw="border-2 w-[150px] h-[150px] grid place-items-center p-4 py-8 rounded-md cursor-pointer"
                  onClick={() => {
                    pushModal({
                      show: true,
                      children: (
                        <div tw="w-[70vw] h-fit">
                          <ResourceUpload
                            fileTypes={[
                              FileTypes.JPEG,
                              FileTypes.JPG,
                              FileTypes.PNG,
                            ]}
                            maxFiles={1}
                            isLogo={true}
                            defaultValue={
                              formData.default_logo
                                ? [formData.default_logo]
                                : undefined
                            }
                            onChange={(value: CustomerResource[]) => {
                              setFormData({
                                ...formData,
                                default_logo: value[0],
                              });
                            }}
                          />

                          <div tw="flex justify-end gap-2">
                            <Button
                              variant="default"
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  default_logo: null,
                                });
                                removeModal('resource-picker');
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => removeModal('resource-picker')}
                            >
                              Use this logo
                            </Button>
                          </div>
                        </div>
                      ),
                      key: 'resource-picker',
                    });
                  }}
                >
                  <Image tw="text-4xl text-gray-400" />
                  Upload a logo
                </div>
              )}
            </div>
          </div>

          {/* Images */}
          <div tw="flex flex-col justify-between">
            <Title3>Default images / videos</Title3>
            <div>
              <div tw="flex gap-4 flex-col md:flex-row ">
                <div tw="flex overflow-x-auto w-fit max-w-full flex-wrap shrink-0 gap-4 flex-col md:flex-row p-[3px] box-border">
                  {formData.default_creatives?.map((creative) => (
                    <div
                      key={creative.id}
                      className="group"
                      tw="relative flex flex-col justify-center shadow-md rounded-md w-36 h-36"
                    >
                      <img
                        src={creative.thumbnail_path}
                        alt="creative"
                        width={150}
                        height={150}
                        tw="rounded-md max-h-[150px]"
                      />
                      <X
                        tw="absolute top-2 right-2 text-2xl text-gray-500 cursor-pointer rounded-full bg-white hidden group-hover:block"
                        size={24}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            default_creatives:
                              formData.default_creatives?.filter(
                                (c) => c.id !== creative.id
                              ),
                          });
                        }}
                      />
                    </div>
                  ))}
                  <div
                    tw="border-2 w-[150px] h-[150px] grid place-items-center p-4 py-8 rounded-md cursor-pointer text-center"
                    onClick={() => {
                      pushModal({
                        show: true,
                        children: (
                          <div tw="w-[70vw] h-fit">
                            <ResourceUpload
                              fileTypes={[
                                FileTypes.JPEG,
                                FileTypes.JPG,
                                FileTypes.PNG,
                                FileTypes.MP4,
                              ]}
                              defaultValue={formData.default_creatives}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  default_creatives: value,
                                });
                              }}
                            />

                            <div tw="flex justify-end gap-2">
                              <Button
                                variant="default"
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    default_creatives:
                                      formData.default_creatives,
                                  });
                                  removeModal('resource-picker');
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={() => removeModal('resource-picker')}
                              >
                                Confirm
                              </Button>
                            </div>
                          </div>
                        ),
                        key: 'resource-picker',
                      });
                    }}
                  >
                    <Image tw="text-4xl text-gray-400 mb-1" />
                    Upload an image
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div tw="lg:col-span-2">
          <div tw="float-right">
            <Button variant="danger" onClick={handleDelete}>
              Delete brand
            </Button>
          </div>
        </div>
      </div>

      {/* Submit button */}
      <div tw="absolute top-[-3.5rem] h-[2rem] right-20 flex mt-4 justify-end">
        <SaveButton
          customStyles={tw`h-[2rem]`}
          type="submit"
          variant="alternative"
          shouldSave={hasUnsavedChanges}
          loading={loading}
        />
      </div>
    </form>
  );
};
