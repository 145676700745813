import { gql } from '@apollo/client';

export const GET_CUSTOM_AD_OVERLAYS = gql`
  query CustomOverlays {
    customOverlays
      @rest(type: "AdOverlays", path: "/v4/ad-overlays", method: "GET") {
      id
      customer_id
      svg
      placement
    }
  }
`;

export const GET_DEFAULT_AD_OVERLAYS = gql`
  query DefaultOverlays {
    defaultOverlays
      @rest(
        type: "DefaultOverlays"
        path: "/v4/default-ad-overlays"
        method: "GET"
      ) {
      id
      svg
      placement
    }
  }
`;
