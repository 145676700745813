import usePermissionContext from 'hooks/context/permission-context';
import MosaicLoader from 'components/loading/MosaicLoader';
import React, { FC } from 'react';
import 'twin.macro';
import { Routes } from 'types/routeTypes';

const LoadingPage: FC = () => {
  const { permissionFetchLoading } = usePermissionContext();

  return (
    <div tw="flex h-screen items-center justify-center">
      {permissionFetchLoading ? (
        <div tw="flex flex-col gap-4 items-center">
          <MosaicLoader size={40} />
          <p tw="text-xl">Loading...</p>
        </div>
      ) : (
        <div tw="flex h-full w-full items-center justify-center bg-gray-50">
          <div tw="w-full max-w-[500px] space-y-8 rounded-xl bg-white p-10 shadow-[0px_0px_12px_-5px_rgba(0,0,0,0.3)]">
            <div tw="text-center">
              <h2 tw="mt-6 text-3xl font-extrabold text-gray-900">
                You're not supposed to be here.
              </h2>
              <p tw="mt-2 text-sm text-gray-600">
                Let&apos;s get you back to where you belong.
              </p>
            </div>
            <div tw="mt-8 space-y-6 flex flex-col gap-8">
              <p tw="text-center text-sm text-gray-500">
                Click the button below to return to the home page.
              </p>
              <a
                tw="mt-5 text-white border bg-sympl px-4 py-2 mx-auto cursor-pointer rounded-md"
                tabIndex={0}
                href={Routes.ROOT}
              >
                Return to Home
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadingPage;
