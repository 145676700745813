import React, { useContext, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'twin.macro';

import Button from 'components/button/Button';
import Checkbox from 'components/form/checkbox/Checkbox';
import { Error } from 'components/typography/Typography';
import Input from 'components/form/input/Input';
import Label from 'components/form/Label';
import {
  FormQuestion,
  FormQuestionType,
} from '../form-questions-list/FormQuestionsList';
import { SurveyContext } from 'context/SurveyContext';
import Dropdown from 'components/dropdown/Dropdown';
import useNavigationContext from 'hooks/context/nav-context';

export interface FormQuestionItemProps {
  question: FormQuestion;
}
interface FormData {
  value: string;
  required: boolean;
  type: FormQuestionType;
}

export const QuestionItemForm: React.FC<FormQuestionItemProps> = ({
  question,
}) => {
  const formMethods = useForm();
  const { changeQuestion, cancelEditQuestion, deleteQuestion } =
    useContext(SurveyContext);

  const { isAdmin } = useNavigationContext();

  const { control, handleSubmit, formState, setError } = formMethods;

  const errors = useMemo(() => formState.errors, [formState.errors]);

  const typeId = 'type';
  const requiredId = 'required';
  const valueId = 'value';

  const onSubmit = (data: FormData) => {
    const { value, type, required } = data;

    const parsedValue = value.trim();

    if (parsedValue === '') {
      setError(valueId, { type: 'required' });
      return;
    }

    changeQuestion?.({
      ...question,
      label: parsedValue,
      required,
      type: type ?? question.type,
    });
  };

  const cancelHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!question) return;
    cancelEditQuestion?.();
  };

  const deleteHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    deleteQuestion?.(question?.ref!);
  };

  return (
    <form tw="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div tw="flex flex-col">
          <Label htmlFor={valueId}>Question</Label>
          <Controller
            id={valueId}
            name={valueId}
            rules={{ required: true }}
            control={control}
            defaultValue={question?.label}
            render={({ value, onChange }) => (
              <Input defaultValue={value} onChange={onChange} />
            )}
          />
          {errors[valueId] !== undefined && (
            <div tw="flex justify-start mb-4">
              <Error>{'The question value is required'}</Error>
            </div>
          )}
        </div>

        {isAdmin && (
          <div tw="flex flex-col mt-2">
            <Label htmlFor={typeId}>Type</Label>
            <Controller
              id={typeId}
              name={typeId}
              rules={{ required: true }}
              control={control}
              defaultValue={question?.type ?? 'long_text'}
              render={({ value, onChange }) => (
                <Dropdown
                  items={[
                    { key: 'long_text', label: 'Long text' },
                    { key: 'short_text', label: 'Short text' },
                    { key: 'rating', label: 'Score (out of 5)' },
                    { key: 'yes_no', label: 'Yes / No' },
                  ]}
                  defaultValue={value}
                  onChange={onChange}
                  disabled={!question.custom}
                />
              )}
            />
            {errors[typeId] !== undefined && (
              <div tw="flex justify-start mb-4">
                <Error>{'The question type is required'}</Error>
              </div>
            )}
          </div>
        )}

        <div tw="flex flex-col mt-2">
          <Label
            htmlFor={requiredId}
            tw="text-sm pb-2 w-min md:pb-0"
            fullWidth={false}
          >
            Required
          </Label>
          <Controller
            id={requiredId}
            name={requiredId}
            rules={{ required: false }}
            control={control}
            defaultValue={question.required}
            render={({ value, onChange }) => (
              <Checkbox
                type="checkbox"
                id={requiredId}
                name={requiredId}
                checked={value}
                onCheck={onChange}
              />
            )}
          />
        </div>
      </div>

      <div tw="w-full flex mt-4">
        <Button variant="danger" onClick={deleteHandler}>
          Delete
        </Button>

        <div tw="flex space-x-2 ml-auto">
          <Button variant="outline" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </div>
      </div>
    </form>
  );
};

export default QuestionItemForm;
