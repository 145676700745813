import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import GuardedRoute from 'routing/GuardedRoute';
import { RouteGuards, Routes as RouteTypes } from 'types/routeTypes';

import CustomerIntake from './CustomerIntake';
import { CustomerBrandProvider } from 'context/CustomerBrandContext';
import { IntakeStepProvider } from 'context/IntakeStepContext';
import { IntakeCoreProvider } from 'context/IntakeCoreContext';

const IntakesCoreRoute = () => {
  return (
    <IntakeCoreProvider>
      <CustomerBrandProvider>
        <Routes>
          <Route
            path=":intake"
            element={
              <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
                <IntakeStepProvider>
                  <CustomerIntake id="intake-container" />
                </IntakeStepProvider>
              </GuardedRoute>
            }
          />
          <Route
            path="/"
            element={
              <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
                <Navigate to={RouteTypes.ROOT} />
              </GuardedRoute>
            }
          />
        </Routes>
      </CustomerBrandProvider>
    </IntakeCoreProvider>
  );
};

export default IntakesCoreRoute;
