import { IntakeQuestion } from 'types/intakeTypes';

export interface IBaseInputProps {
  section: string;
  question: IntakeQuestion;
}

export type Language = {
  name: string;
  levels: string[];
};

export type Coworker = {
  firstname: string;
  lastname: string;
  email: string;
  function: string;
  isLeadRecruiter: boolean;
  mobile?: string;
};

export type FormData = {
  [questionId: number]: string | string[] | Language[] | number | Coworker[];
};

export type IntakeFormResults = {
  intake: number;
  section: number;
  step: number;
  questions: IntakeFormResult[];
  completed?: boolean;
};

export type IntakeFormResult = {
  id: number;
  value: string | string[] | Language[] | number | Coworker[];
  annotation?: string;
};

export enum LANGUAGES {
  Danish = 111,
  Dutch = 385,
  English = 141,
  Finnish = 161,
  French = 167,
  German = 115,
  'German(Switzerland)' = 116,
  Italian = 237,
  Polish = 425,
  Portuguese = 432,
  Spanish = 148,
  Swedish = 512,
}
