import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { RouteGuards, Routes } from 'types/routeTypes';
import { getCurrentAuthToken } from 'utils/authHelpers';
import usePermissionContext from 'hooks/context/permission-context';
import LoadingPage from 'views/LoadingPage';
import * as Sentry from '@sentry/react';
import useNavigationContext from 'hooks/context/nav-context';
import { getLocalAppSession } from 'utils/storageHelpers';

const GuardedRoute: React.FC<{
  guard: RouteGuards;
  allowedPermissions?: string[];
  requiredPermissionLevel?: 'brand' | 'customer' | 'vacancy';
  children: React.ReactNode;
}> = ({ children, guard, allowedPermissions, requiredPermissionLevel }) => {
  const authenticated = getCurrentAuthToken();
  const {
    userHasPermission,
    permissionFetchLoading,
    currentVacPermissions,
    permissionData,
  } = usePermissionContext();
  const { activeCustomer, activeVacancy } = useNavigationContext();
  const getRedirectPath = (
    path: string,
    paramString: string
  ): string | null => {
    // Authentication flow
    if (authenticated && isAuthFlowPath(path)) {
      if (path.includes(Routes.CONFIRM_PASSWORD)) {
        // Allows users to use their password reset link to visit an intake
        const hash = getConfirmPasswordHash(paramString);
        return hash ? `${Routes.INTAKE}/${hash}` : Routes.ROOT;
      } else return Routes.ROOT;
    } else if (!authenticated && isAuthFlowPath(path)) {
      return null;
    }

    // Root-level flow
    if (authenticated && path === Routes.ROOT) {
      return null;
    } else if (!authenticated && path === Routes.ROOT) {
      return Routes.LOGIN;
    }

    // Basic route flow
    if (!authenticated && guard === RouteGuards.AUTHENTICATED) {
      localStorage.setItem(
        'referrer',
        window.location.pathname + window.location.search
      );
      return Routes.LOGIN;
    }

    return null;
  };

  const getQueryParams = (paramString: string) => {
    return paramString
      .substr(1, paramString.length - 1)
      .split('&')
      .map((param) => param.split('='))
      .map((paramKvp) => ({
        key: paramKvp[0],
        value: paramKvp[1],
      }));
  };

  const getConfirmPasswordHash = (paramString: string) => {
    return getQueryParams(paramString).find((param) => param.key === 'hash')
      ?.value;
  };

  const isAuthFlowPath = (path: string) => {
    return (
      path.includes(Routes.LOGIN) ||
      path.includes(Routes.FORGOT_PASSWORD) ||
      path.includes(Routes.CONFIRM_PASSWORD) ||
      path.includes(Routes.REGISTER) ||
      path.includes(Routes.DEMO)
    );
  };

  if (allowedPermissions && permissionFetchLoading)
    return (<LoadingPage />) as ReactElement;

  if (
    authenticated &&
    activeVacancy &&
    !permissionFetchLoading &&
    allowedPermissions &&
    !userHasPermission(allowedPermissions, requiredPermissionLevel)
  ) {
    if (import.meta.env.PROD) {
      const errorDetails = {
        route: window.location.pathname,
        referrer: window.location.origin ?? document.referrer ?? 'No referrer',
        user: getLocalAppSession()?.email ?? 'No Email',
        permissionFetchLoading,
        allowedPermissions,
        activeCustomer,
        currentVacPermissions,
        activeVacancy,
        permissionData,
      };

      const error = new Error(
        `User does not have permission to access this route: ${errorDetails.route}. Original referrer: ${errorDetails.referrer}`
      );

      // Add additional context to the error
      Sentry.setExtras(errorDetails);
      Sentry.setContext('error', errorDetails);
      Sentry.captureException(error);
    }
    return (<Navigate to={Routes.NOT_ALLOWED} />) as ReactElement;
  }

  const pathname = getRedirectPath(
    window.location.pathname,
    window.location.search
  );
  if (pathname !== null) return (<Navigate to={pathname} />) as ReactElement;
  return (<>{children}</>) as ReactElement;
};

export default GuardedRoute;
