import React, { useState } from 'react';
import { theme } from 'twin.macro';

import RegisterForm, {
  RegisterFormData,
} from 'components/form/register/RegisterForm';
import SvgSympl from 'components/svg/SvgSympl';
import { Routes } from 'types/routeTypes';
import { activateUserSession } from 'utils/authHelpers';
import { fireEvent } from 'utils/eventHelper';
import { registerUser } from 'api/auth/authApi';

import useGetPageParamsConfig from 'hooks/page-params/useGetPageParamsConfig';
import { RegisterPageConfig } from 'config/register';
import usePageSearchParams from 'hooks/page-params/usePageSearchParams';
import {
  Body,
  Headline,
  Title1,
  Title2,
} from 'components/typography/Typography';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import { useNavigate } from 'react-router-dom';

export interface RegistrationPayload {
  email: string;
  first_name: string;
  last_name: string;
  company: string;
  password: string;
  job_position: string;
  phone: string;
  query_string?: string;
  url?: string;
}

export interface RegistrationResponse {
  token: string;
  first_name: string;
  last_name: string;
  language_id: string;
  vacancy_id: number;
  customer_id: number;
}

const Register: React.FC = () => {
  const navigate = useNavigate();
  const { query: searchQuery, params: searchParams } = usePageSearchParams();

  const { title, subtitle, cta, next, bulletPoints } = useGetPageParamsConfig<
    { variant: string },
    RegisterPageConfig
  >({
    page: 'register',
    configKey: 'variant',
  });

  const [registrationLoading, setRegistrationLoading] = useState(false);

  const { addToast } = useToastNotifications();

  const getRegisterPayload = (data: RegisterFormData): RegistrationPayload => {
    return {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      company: data.company,
      password: data.password,
      job_position: 'Recruiter',
      phone: data.phone,
      query_string: searchQuery,
      ...(data.url && { url: data.url }),
    };
  };

  const submitFormHandler = async (data: RegisterFormData) => {
    setRegistrationLoading(true);

    let res: RegistrationResponse;
    const payload = getRegisterPayload(data);

    try {
      res = await registerUser(payload);
      if (!res?.token) return;
    } catch (error) {
      addToast({
        description: 'We were unable to register you, please try again.',
        type: ToastTypes.ERROR,
      });

      setRegistrationLoading(false);
      return;
    }

    await activateUserSession({
      token: res.token,
      uid: data.email,
      firstName: res?.first_name,
      lastName: res?.last_name,
      vacancyId: res?.vacancy_id,
      customerId: res?.customer_id,
      trackLogRocket: true,
    });

    fireEvent('ss_register_done', {
      email: payload.email,
      firstName: payload.first_name,
      lastName: payload.last_name,
      jobPosition: payload.job_position,
      phone: payload.phone,
      querystring: searchQuery,
    });

    setRegistrationLoading(false);

    navigate(
      searchQuery.includes('variant=academy')
        ? Routes.ACADEMY
        : Routes.GETTING_STARTED,
      {
        replace: true,
        state: {
          demo: searchQuery.includes('demo'),
        },
      }
    );
  };

  return (
    <div tw="w-full h-full flex flex-col justify-center items-center lg:(flex-row)">
      <div tw="flex lg:(justify-center items-center w-1/2 max-h-full) p-2">
        <div tw="flex flex-col max-w-lg lg:(max-h-screen px-2 pb-4) overflow-auto">
          <div tw="justify-start block -ml-2 h-16 w-auto 2xl:h-24">
            <SvgSympl />
          </div>
          <div tw="text-gray-700 mt-2 mb-6">
            <Title1 isBold={false} mb={0}>
              {title}
            </Title1>
            {subtitle && <Headline mb={0}>{subtitle}</Headline>}
          </div>
          <RegisterForm
            onSubmit={(data) => submitFormHandler(data)}
            loading={registrationLoading}
            buttonLabel={cta}
            searchParams={searchParams}
          />
        </div>
      </div>

      <div tw="hidden lg:(w-1/2 h-full mt-0 flex justify-center items-center relative bg-gray-50)">
        <div tw="rounded-lg shadow-sm bg-white p-8 flex flex-col z-10 overflow-hidden absolute">
          <Title2 mb={3}>{next}</Title2>
          <ul>
            {bulletPoints?.map((bullet, bulletIdx) => (
              <li tw="flex items-center mb-4 last-of-type:m-0" key={bulletIdx}>
                <Body>{bullet}</Body>
              </li>
            ))}
          </ul>
        </div>
        <svg
          viewBox="0 0 500 500"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          id="blobSvg"
          transform="scale(-1, 1)"
        >
          <path
            id="blob"
            d="M300.5,349.5Q135,449,141.5,261Q148,73,307,161.5Q466,250,300.5,349.5Z"
            fill={theme`colors.red.100`}
          />
        </svg>
      </div>
    </div>
  );
};

export default Register;
