import React, { useCallback } from 'react';
import useNavigationContext from 'hooks/context/nav-context';
import InfiniteApiDropDown from '../dropdown/infinite-api-dropdown/InfiniteApiDropdown';
import { GET_CUSTOMERS_PAGINATED } from '../../graphql/customers/queries';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'types/routeTypes';

interface AccountSelectorItem {
  id: number;
  name: string;
}

const AccountSelector: React.FC = () => {
  const { setActiveCustomer, customer, isAdmin, activeCustomer } =
    useNavigationContext();
  const navigate = useNavigate();

  const customerName = customer?.name ?? '';

  const accountClickHandler = useCallback(
    (customer: AccountSelectorItem) => {
      setActiveCustomer(customer.id);
      navigate(Routes.ROOT);
    },
    [setActiveCustomer]
  );

  return (
    <InfiniteApiDropDown
      key={customerName + '-key'} // ensures rerender
      onClick={(item) => accountClickHandler(item as AccountSelectorItem)}
      defaultValue={
        isAdmin ? `${activeCustomer} - ${customerName}` : customerName
      }
      API={GET_CUSTOMERS_PAGINATED}
      dataKey={'customers'}
      labels={isAdmin ? ['id', 'name'] : ['name']}
    />
  );
};

export default AccountSelector;
