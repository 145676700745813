import React, { ReactNode, cloneElement, isValidElement } from 'react';
import usePermissionContext from 'hooks/context/permission-context';
import ToolTip from 'components/tooltip/ToolTip';
import 'twin.macro';

interface PermissionWrapperProps {
  permission: string | string[];
  on?: 'brand' | 'customer' | 'vacancy' | undefined;
  children: ReactNode;
  tooltipText?: string;
}

const PermissionWrapper: React.FC<PermissionWrapperProps> = ({
  permission,
  on = 'vacancy',
  children,
  tooltipText,
}) => {
  const { userHasPermission } = usePermissionContext();
  const isAllowed = userHasPermission(permission, on);

  if (isAllowed) return <React.Fragment>{children}</React.Fragment>;

  const disabledChild = isValidElement(children)
    ? cloneElement(children as React.ReactElement<any>, {
        onClick: (e: React.MouseEvent) => e.preventDefault(), // Disable click events
        disabled: true,
        style: { pointerEvents: 'none', ...children.props.style },
      })
    : children;

  return (
    <ToolTip
      text={tooltipText || 'You do not have permission to perform this action'}
    >
      <div tw="cursor-not-allowed">{disabledChild}</div>
    </ToolTip>
  );
};

export default PermissionWrapper;
