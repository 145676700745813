import 'twin.macro';
import React from 'react';
import { WarningCircle } from '@phosphor-icons/react';

import { getCurrentAuthToken } from 'utils/authHelpers';
import { Routes } from 'types/routeTypes';

export const ErrorPage: React.FC = () => {
  const isAuthenticated = getCurrentAuthToken();

  return (
    <div tw="flex h-full w-full items-center justify-center bg-gray-50">
      <div tw="w-full max-w-[500px] space-y-8 rounded-xl bg-white p-10 shadow-[0px_0px_12px_-5px_rgba(0,0,0,0.3)]">
        <div tw="text-center">
          <WarningCircle
            weight="bold"
            width={48}
            height={48}
            color="#ef4444"
            tw="mx-auto"
          />
          <h2 tw="mt-6 text-3xl font-extrabold text-gray-900">
            You encountered an error
          </h2>
        </div>
        <div tw="mt-8 space-y-6 flex flex-col gap-8">
          <p tw="text-center text-sm text-gray-500">
            It seems that something went wrong, please try again.
          </p>
          <a
            tw="mt-5 text-sympl border border-sympl px-4 py-2 mx-auto cursor-pointer hover:bg-red-50"
            tabIndex={0}
            href={isAuthenticated ? Routes.ROOT : Routes.LOGIN}
          >
            {isAuthenticated ? 'Go to my dashboard' : 'Go back'}
          </a>
        </div>
      </div>
    </div>
  );
};
