import React, { useEffect, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';

import Navbar from 'components/navbar/Navbar';
import { throttle } from 'lodash';
import useWindowDimensions from 'hooks/viewport';

const PageLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Keep track if you want to hide the nav bar
  const [hideNav, setHideNav] = useState(false);

  // Keep track of the last scroll position
  const lastYPos = useRef(0);

  // Keep track of window size
  const windowDimensions = useWindowDimensions();

  /**
   * Create a queryselector that listens for scrolling on the main element
   * Only add the listener when on mobile view --> keep screenwidth as dependency
   * If scrolling down, hide the nav
   * If scrolling up, show the nav
   */
  useEffect(() => {
    // Don't add an eventlistener when on desktop view
    if (windowDimensions.width >= 1024) {
      return;
    }

    const main = document.querySelector('main#scroll-listener')! as HTMLElement;

    /*
     * When setting navHide to true (or false), the height of the scrolling container changes
     * Keep track of the initial height, to not trigger a scroll event while resizing
     */
    let mainInitialHeight = main.offsetHeight;

    const handleScroll = () => {
      // New Y position
      const newY = main.scrollTop;

      // New scroll container height
      const mainCurrentHeight = main.offsetHeight;

      // Prevent a state change while resizing
      if (mainInitialHeight !== mainCurrentHeight) {
        mainInitialHeight = mainCurrentHeight;
      } else if (newY > lastYPos.current) {
        // Hide nav when scrolling down
        setHideNav(true);
      } else if (newY < lastYPos.current) {
        // Shown nav when scrolling up
        setHideNav(false);
      }
      // Update last Y position
      lastYPos.current = newY;
    };

    // Make it so that the function is invoked once at the start, then at most every 250ms
    // Prevents overload of function calls when continously scrolling
    const throttledHandleScroll = throttle(handleScroll, 250);

    // Add scroll
    main.addEventListener('scroll', throttledHandleScroll);

    // Clean up function should remove the listener to prevent multiple listeners on main after refresh
    return () => {
      main.removeEventListener('scroll', throttledHandleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowDimensions]);

  // Keep track of navbar open state (handle in parent since it should prevent the hiding of the navbar)
  const [navbarIsOpen, setNavbarIsOpen] = useState(false);

  return (
    <Wrapper>
      <NavScrollHider hideNav={hideNav && !navbarIsOpen}>
        <Navbar isOpen={navbarIsOpen} setIsOpen={setNavbarIsOpen} />
      </NavScrollHider>
      <main
        id="scroll-listener"
        tw="w-full max-w-full h-full relative overflow-auto"
      >
        <div
          id="page-modal-root"
          tw="fixed hidden bg-gray-600 bg-opacity-50 overflow-y-hidden h-full w-[calc(100% - 250px)] z-40"
        ></div>
        {children}
      </main>
    </Wrapper>
  );
};

const Wrapper = styled.div(
  tw`w-full h-full grid grid-rows-[max-content 1fr] lg:flex`
);

const NavScrollHider = styled.div<{ hideNav: boolean }>`
  ${tw`transition-[max-height] ease-in-out duration-1000 max-h-[500px] lg:(max-h-full transition-none) min-w-fit`}
  ${({ hideNav }) => hideNav && tw`max-h-0 overflow-y-hidden`}
`;

export default PageLayout;
