import React, { useMemo } from 'react';
import PageLayout from 'components/page/page-layout/PageLayout';
import PageWrapper from 'components/page/page-wrapper/PageWrapper';
import PageHeading from 'components/page/page-heading/PageHeading';
import { FeedbackWidgetProps } from 'components/feedback/FeedbackWidget';
import BlurredPage from 'components/page/app-page/BlurredPage';
import useNavigationContext from 'hooks/context/nav-context';
import SubscriptionPopupContent from 'components/page/app-page/popup-content/SubscriptionPopupContent';
import SideBar from 'components/sideBar/SideBar';
import tw, { TwStyle, styled } from 'twin.macro';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import { CSSProp } from 'styled-components';
import { Routes } from 'types/routeTypes';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import DemoPopupContent from './popup-content/DemoPopupContent';

export interface AppPageProps {
  children: React.ReactNode;
  heading?: string;
  loading?: boolean;
  skeleton?: React.ReactNode;
  disablePadding?: boolean;
  topPaddingOnly?: boolean;
  breadcrumbUrl?: string;
  feedback?: Required<Omit<FeedbackWidgetProps, 'defaultValue'>>;
  enableFeedback?: boolean;
  cta?: React.ReactNode;
  clipboardCopy?: React.ReactNode;
  tabs?: React.ReactNode[];
  activeTabIndex?: number;
  onTabChange?: (currentTabIndex: number) => void;
  allowPageWithoutSubscription?: boolean;
  allowPageWithoutVacancy?: boolean;
  sideBarContent?: React.ReactNode;
  sideBarMenu?: AdEditorSideBarTabs[];
  isSideBarOpen?: boolean;
  setActiveTab?: (value?: AdEditorSideBarTabs) => void;
  styles?: CSSProp;
  isTabsDisabled?: boolean;
  rightPanelClass?: TwStyle;
}

const AppPage: React.FC<AppPageProps> = ({
  cta,
  clipboardCopy,
  tabs,
  feedback,
  heading,
  children,
  skeleton,
  loading = false,
  activeTabIndex = 0,
  enableFeedback = false,
  disablePadding = false,
  topPaddingOnly = false,
  breadcrumbUrl,
  onTabChange,
  allowPageWithoutSubscription = false,
  allowPageWithoutVacancy = false,
  sideBarContent,
  setActiveTab,
  isSideBarOpen,
  sideBarMenu,
  styles = {},
  isTabsDisabled,
  rightPanelClass,
}) => {
  const {
    activeVacancy,
    vacIsPublished: isPublished,
    customer,
    vacancyLoading,
    vacIsBooster: isBooster,
    hasVacancies,
    isAdmin,
    subscription,
  } = useNavigationContext();

  const { activeTab } = useAdEditorContext();
  const showSubscriptionPopup = useMemo(
    () =>
      subscription?.status !== 'ACTIVE' &&
      !customer &&
      !allowPageWithoutSubscription,
    [subscription, customer, allowPageWithoutSubscription]
  );

  // TODO: rendering of popup is caused by the customer being null - 27/12/2024
  const showPopup = useMemo(
    () =>
      (!customer && !activeVacancy && !allowPageWithoutVacancy) ||
      (showSubscriptionPopup && !isAdmin),
    [activeVacancy, showSubscriptionPopup, isAdmin, customer]
  );

  const popupContent = showSubscriptionPopup ? (
    <SubscriptionPopupContent />
  ) : (
    <DemoPopupContent title="Welcome!" listItems={[]} />
  );

  // POSSIBLE SCENARIOS
  // 1. loading
  // 2. mock (+campaign selection / creation)
  // 3. children / normal content
  // 4. campaign selection / creation (can we deprecate when we have 2?)

  // 1. loading
  if (vacancyLoading || loading) {
    return (
      <PageLayout>
        <PageWrapper>
          <div tw="h-full flex flex-col pt-4 overflow-y-hidden">
            <BaseSkeleton />
            {skeleton ?? (
              <div tw="bg-gray-200 h-full w-full animate-pulse rounded-md" />
            )}
          </div>
        </PageWrapper>
      </PageLayout>
    );
  }

  // Normal page flow
  return (
    <PageLayout>
      <RightPanel
        customClass={rightPanelClass}
        isBgGray={
          !hasVacancies && window.location.pathname === Routes.GETTING_STARTED
        }
      >
        <PageWrapper
          disableContainer={disablePadding}
          heading={
            <PageHeading
              tabs={tabs}
              feedback={feedback}
              activeTabIndex={activeTabIndex}
              loading={loading}
              onTabChange={onTabChange}
              title={heading}
              cta={!showSubscriptionPopup && cta}
              clipboardCopy={clipboardCopy}
              enableFeedback={!isPublished && isBooster && enableFeedback}
              breadcrumbUrl={breadcrumbUrl}
              isTabsDisabled={isTabsDisabled}
            />
          }
        >
          <div
            tw="min-[1537px]:(h-full) lg:h-full min-h-full h-auto w-full box-border"
            css={[
              disablePadding ? tw`p-0` : tw`py-8`,
              topPaddingOnly && tw`pb-2`,
            ]}
          >
            {showPopup ? (
              // 2. mock (+campaign selection / creation)
              <BlurredPage popup={popupContent} />
            ) : (
              // 3. children / normal content
              <Content activeTab={activeTab}>
                <div
                  className="content"
                  tw="flex-1 max-w-full h-full overflow-y-auto no-scrollbar"
                  css={styles}
                >
                  {children}
                </div>

                {sideBarContent && (
                  <SideBar
                    isOpen={isSideBarOpen}
                    activeTabIndex={activeTabIndex}
                    setActiveTab={setActiveTab}
                    menu={sideBarMenu}
                  >
                    {sideBarContent}
                  </SideBar>
                )}
              </Content>
            )}
          </div>
        </PageWrapper>
      </RightPanel>
    </PageLayout>
  );
};

const RightPanel = styled.div<{
  customClass?: TwStyle;
  isBgGray?: boolean;
}>`
  ${tw`relative min-h-full lg:(h-full flex flex-col)`}
  ${({ isBgGray }) => isBgGray && tw`bg-gray-50`}
  ${({ customClass }) => customClass}
`;

const BaseSkeleton: React.FC = () => (
  <div tw="grid grid-cols-4 gap-4">
    <div tw="col-span-3">
      <div tw="bg-gray-200 h-8 w-full animate-pulse rounded-md mb-4" />
      <div tw="bg-gray-200 h-10 w-2/3 animate-pulse rounded-md mb-4" />
    </div>
    <div tw="grid grid-cols-2 gap-4">
      <div tw="bg-gray-200 h-8 w-full animate-pulse rounded-md mb-4" />
      <div tw="bg-gray-200 h-8 w-full animate-pulse rounded-md mb-4" />
    </div>
  </div>
);

const Content = styled.div<{ activeTab?: AdEditorSideBarTabs }>`
  ${tw`flex h-full lg:(flex-row)`}
  ${({ activeTab }) => activeTab && tw`flex-col`}
`;

export default AppPage;
